import React from 'react';
import { Facebook, Instagram, Twitter } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <Container fluid='xxl' className='btn-gradient'>
      <div className='d-flex align-items-center justify-content-between p-4 mt-2'>
        <div>
          <h6 className='mb-0'>
            Copyright © 2023 ChamCast. All rights reserved.
          </h6>
        </div>
        <div className='d-flex gap-3'>
          <Facebook />
          <Twitter />
          <Instagram />
        </div>
      </div>
    </Container>
  );
};

export default Footer;
