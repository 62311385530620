import { useRef, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons';

const Upload = () => {
  const showReelInputRef = useRef(null);
  const portfolioInputRef = useRef(null);
  const [reelsImage, setReelsImage] = useState([]);

  const handleShowReelsClick = () => {
    showReelInputRef.current.click();
  };

  const handleShowReelsFileChange = (e) => {
    const file = e.target.files[0];
    setReelsImage([...reelsImage, URL.createObjectURL(file)]);
    console.log(reelsImage);
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...reelsImage];
    updatedFiles.splice(index, 1);
    setReelsImage(updatedFiles);
  };

  return (
    <>
      {reelsImage.map((file, index) => (
        <div className='w-25 position-relative' key={index}>
          <Image
            src={file}
            className='img-border-radius w-100'
            alt='profile image'
            rounded
            style={{ height: '90px' }}
          />
          <button
            className='position-absolute p-0 border-0 rounded-circle d-flex align-items-center justify-content-center'
            onClick={() => handleRemoveImage(index)}
            aria-label='close icon'
            style={{
              top: '1px',
              right: '1px',
              background: 'rgba(255,255,255,50%)',
              cursor: 'pointer',
              width: '16px',
              height: '16px'
            }}
          >
            <X color='#E02020' />
            <span className='visually-hidden'>close icon</span>
          </button>
        </div>
      ))}
      <div
        className='w-25 d-flex flex-column align-items-center text-center justify-content-center'
        onClick={handleShowReelsClick}
        style={{
          backgroundColor: '#E2E2E2',
          border: '1px solid #979797',
          borderRadius: '15px',
          height: '90px',
          color: '#8a8a8a'
        }}
      >
        <Form.Control
          type='file'
          className='d-none'
          multiple
          ref={showReelInputRef}
          onChange={handleShowReelsFileChange}
        />
        <Plus color='#0D1C2E' width={20} height={20} />
        Add Photo
      </div>
    </>
  );
};

export default Upload;
