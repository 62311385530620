import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, InputGroup, Button, Alert } from 'react-bootstrap';
import { BASE_URL } from '../../GlobalUtil';


const ProjectModal = ({ show, onHide, actorIdNum }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(null);
  const [projectTitle, setProjectTitle] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null)

  const handleCheckboxChange = (projectId) => {
    // setSelectedProjects(projectId);
    if (selectedProjects === projectId) {
      setSelectedProjects(null);
    } else {
      setSelectedProjects(projectId);
    }
  };
  // console.log("num", actorIdNum)

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post(`${BASE_URL}/api/projects/talents/shortlist`, {
        talent: actorIdNum,
        project: selectedProjects
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response.data);
      setSuccess("Added successfully");
      setTimeout(() => {
        onHide();
        setSuccess(null)
      }, 2000);
      // onHide();
    } catch (error) {
      console.error('Error adding projects:', error);
      setError("Unable to post. Maybe you already added.");
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };
  

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${BASE_URL}/api/projects/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, [projectTitle]);


  const handleCreateProject = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post(`${BASE_URL}/api/projects/`, {
        name: projectTitle,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response.data);
      setProjectTitle("")
      // onHide();
    } catch (error) {
      console.error('Error creating project:', error);
      setError("Unable to Create project");
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select project</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
        <Form>

{projects && projects.length > 0  && projects.map(project => (
            <InputGroup key={project.project_id}>
              <InputGroup.Checkbox id={`project-${project.project_id}`}
              checked={selectedProjects === project.project_id} onChange={() => handleCheckboxChange(project.project_id)} aria-label="Checkbox for following text input" />
              <Form.Control aria-label="Text input with checkbox" value={project.name} disabled={true} />
            </InputGroup>
          ))}

          {/* <InputGroup>
            <InputGroup.Checkbox aria-label="Checkbox for following text input" />
            <Form.Control aria-label="Text input with checkbox" value="Project 1" disabled={true} />
          </InputGroup> */}

          <InputGroup>
            <InputGroup.Checkbox disabled aria-label="Checkbox for following text input" />
            <Form.Control placeholder='Add a new project title' aria-label="Text input with checkbox" value={projectTitle} disabled={false} onChange={(e) => setProjectTitle(e.target.value)} />
            <Button onClick={handleCreateProject} variant="outline-secondary" id="button-addon2">
          Create
        </Button>
          </InputGroup>
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!selectedProjects}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectModal;