import React from 'react';
import { Button } from 'react-bootstrap';

const ProjectPopup = ({ onClose }) => {
  const handleCancel = () => {
    onClose(); // Call the onClose function passed from the parent component
  };
  return (
    <div
      className='w-50 mx-auto mt-2 img-border-radius popup-shadow p-4 '
      style={{
        zIndex: '100',
        position: 'absolute',
        top: '150px',
        background: '#f8f9fa',
        color: '',
      }}
    >
      <div className='w-100 d-flex justify-content-between'>
        <h2 className='p-2'>My projects</h2>
        <span
          className='h5 pointer-cursor'
          onClick={handleCancel}
          style={{ cursor: 'pointer' }}
        >
          <i className='fa-solid fa-xmark ' />
        </span>
      </div>

      <div className='d-flex gap-5 img-border-radius flex-wrap w-100'>
        <div
          className='border  w-25 pointer img-border-radius 
     d-flex align-items-center justify-content-center'
        >
          <h1 className='text-center ' style={{ fontSize: '50px' }}>
            +
          </h1>
        </div>
        <div className='popup-shadow project-hover  text-center w-50 py-4 px-4  gap-5 pointer img-border-radius d-flex align-items-center justify-content-between'>
          <div className='popup-shadow w-25 text-center p-4'>
            <h1 className='text-center w-100 h-100 mx-auto'>P</h1>
          </div>
          <div className='text-start'>
            <h4>Pizza 3</h4>
            <p className='text-muted'>
              The story is about a pizza delivery man named Michael who lands in
              a mysterious predicament, and how it affects his life.
            </p>
            <h6 className='text-muted' style={{ textDecoration: 'none' }}>
              15 shortlist | 01-04-2024
            </h6>
          </div>
        </div>
      </div>

      <div className='d-flex gap-5 mt-5'>
        <Button variant='outline-primary'>Select</Button>
        <Button variant='outline-danger'>Cancel</Button>
      </div>
    </div>
  );
};

export default ProjectPopup;
