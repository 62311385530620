import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
// import Login from './components/Auth-pages/Login';
import MyWorkPopUp from './components/PopupMsg/MyWorkPopUp';
import MyProfile from './components/Profile/MyProfile';
import MyWork from './components/Profile/MyWork';
import ProfileEdit from './components/Profile/ProfileEdit';
import CreateProject from './components/pages/CreateProject';
import ProjectPage from './components/pages/ProjectPage';
import ShortlistesTalent from './components/pages/ShortlistesTalent';
import TalentDetail from './components/pages/TalentDetail';
import Talent from './components/pages/talent';
import { AuthContext } from './context/AuthContext';
import './css/custom.css';

function App() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <>
      {/* {isLoggedIn ? ( */}
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<HomePage />} /> */}
          <Route path='/talent' element={<Talent />} />
          {/* <Route path='/casting' element={<TalentHomePage />} /> */}
          <Route path={'/talentdetail/:tid'} element={<TalentDetail />} />
          <Route
            path='/shortlisted/:project_id'
            element={<ShortlistesTalent />}
          />
          <Route path='/myprojects' element={<ProjectPage />} />
          <Route path='/profileedit' element={<ProfileEdit />} />
          <Route path='/mywork' element={<MyWork />} />
          <Route path='/workpop' element={<MyWorkPopUp />} />
          {/* <Route path='/login' element={<Login />} /> */}

          {/* <Route path='/signup' element={<SignUp />} />
            <Route path='/forgot-password' element={<ForgetPassword />} />
            <Route path='/verify' element={<VerifyOTP />} />
            
            <Route path='/company' element={<RegisterAsCompany />} />
            <Route path='/home' element={<HomePage />} /> */}
          <Route path='/create/project' element={<CreateProject />} />
          <Route path='/myprofile' element={<MyProfile />} />
          <Route path='*' element={<div>Page Not Found</div>} />
        </Routes>
      </BrowserRouter>
      {/* ) : (
        <>{window.location.replace('https://dev.chamcast.com/login')}</>
      )} */}
    </>
  );
}

export default App;
