import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { Check, Plus, Star } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../GlobalUtil';

const MyProjectDropdown = () => {
  const [showModal, setShowModal] = useState(false);
  const [projectName, setProjectName] = useState([]);
  const [savedProjectName, setSavedProjectName] = useState('');
  const [myprojects, setMyProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [selectedProjectTalents, setSelectedProjectTalents] = useState(null);

  const handleProjectClick = (projectId, projectName, talentCount) => {
    setSelectedProject(projectId);
    setSelectedProjectName(projectName);
    setSelectedProjectTalents(talentCount);
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (selectedProject) {
      navigate(`/shortlisted/${selectedProject}`);
    }
  };
  const handleShow = () => {
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  const myProjectsUrl = `${BASE_URL}/api/projects/`;

  useEffect(() => {
    const MyProjects = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(myProjectsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setMyProjects(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    MyProjects();
  }, [myProjectsUrl]);
  const AddProject = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const data = {
        name: projectName
      };
      const response = await axios.post(myProjectsUrl, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 201) {
        window.location.reload();
      }
      setSavedProjectName(response.data.name);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSave = async () => {
    await AddProject(projectName);
    setShowModal(false);
  };

  const spanStyle = {
    backgroundColor: '#7ebc11',
    borderRadius: '50%',
    padding: '5px 10px',
    color: 'white',
    marginLeft: '5px'
  };

  return (
    <>
      <Dropdown align='end' style={{ color: '#808F84' }} className='pe-3'>
        <Dropdown.Toggle
          as='div'
          className='d-flex align-items-center gap-2'
          role='button'
        >
          <Star className='mx-1' />
          <div>
            {selectedProjectName ? (
              <div>
                {selectedProjectName}
                <span style={spanStyle}>{selectedProjectTalents}</span>
              </div>
            ) : (
              'My Projects'
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className='chamcast-login-myproject-list text-center'
          style={{ maxHeight: '500px', overflowY: 'auto' }}
        >
          <span className='arrow-up'></span>
          <Dropdown.ItemText className='text-muted text-nowrap'>
            To book talents create new project
          </Dropdown.ItemText>
          <Dropdown.ItemText className='text-muted my-2 d-grid'>
            <Button variant='outline-dark' onClick={handleShow}>
              <Plus /> Create New Project
            </Button>
          </Dropdown.ItemText>
          <Dropdown.Divider />
          <Dropdown.ItemText className='text-muted'>
            Select Project
          </Dropdown.ItemText>
          {myprojects &&
            myprojects.map((project) => (
              <Dropdown.Item
                key={project.project_id}
                style={{ textAlign: 'start' }}
              >
                <div
                  className='my-2'
                  onClick={() =>
                    handleProjectClick(
                      project.project_id,
                      project.name,
                      project.shortlist_count
                    )
                  }
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div>
                    <h5>{project.name}</h5>
                    <p className='text-muted'>
                      {project.shortlist_count} talents
                    </p>
                  </div>
                  {selectedProject === project.project_id && (
                    <Check
                      style={{
                        marginLeft: 'auto',
                        color: 'green',
                        fontSize: '50px'
                      }}
                    />
                  )}
                </div>
              </Dropdown.Item>
            ))}
          <Dropdown.ItemText className='d-grid'>
            <Button variant='dark' className='my-2' onClick={handleNavigate}>
              BOOK NOW
            </Button>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <div>
            <h2 className='text-center font-weight-bold'>Create New Project</h2>
            <p className='text-muted'>
              Enter your project name and shortlist talents
            </p>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Project Name *</Form.Label>
              <Form.Control
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                type='text'
                placeholder=''
              />
            </Form.Group>
            <div className='d-flex gap-3 align-items-center'>
              <Button
                onClick={handleClose}
                style={{ background: '#979797', border: 'none' }}
              >
                Cancel
              </Button>
              <Button onClick={handleSave} className='btn-gradient border-0'>
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyProjectDropdown;
