import React, { useState } from 'react';
// import axios from 'axios';
import Form from 'react-bootstrap/Form';
import '../SideBar/Sidebar.css'
import DualSlider from './Dualslider';
import { Button } from 'react-bootstrap';
import { BASE_URL } from '../../GlobalUtil';



const Sidebar = ({onApplyFilters}) => {
  // const [filter,setFilter]=useState({
  //   gender:'',
  //   languages:''
  // })
  // Below is the states for the filter 
 
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedEthnicities, setSelectedEthnicities] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const formatHeight = (feet) => {
    const feetPart = Math.floor(feet);
    const inchesPart = Math.round((feet - feetPart) * 12);
    return `${feetPart}’${inchesPart}”`;
};


 const handleApplyFilters = () => {
    let urlFilter = `${BASE_URL}/api/talent/list?`;

    // Append selected languages to the URL filter
    if (selectedLanguages.length > 0) {
        urlFilter += `languages=${selectedLanguages.join(',')}&`;
    }

    // Append selected genders to the URL filter
    if (selectedGenders.length > 0) {
        urlFilter += `gender=${selectedGenders.join(',')}&`;
    }

    // Append selected skills to the URL filter
    if (selectedSkills.length > 0) {
        urlFilter += `skills=${selectedSkills.join(',')}&`;
    }

    // Append selected interests to the URL filter
    if (selectedInterests.length > 0) {
        urlFilter += `interests=${selectedInterests.join(',')}&`;
    }

    // Append selected ethnicities to the URL filter
    if (selectedEthnicities.length > 0) {
        urlFilter += `ethnicities=${selectedEthnicities.join(',')}&`;
    }

    // Append selected height range to the URL filter
      // urlFilter += `height=${heightSliderValues.join(',')}&`;
     
     // Append selected age range to the URL filter
      //  urlFilter += `min_height=${heightSliderValues[0]}&max_height=${heightSliderValues[1]}&`;
    // Append selected height range to the URL filter
      urlFilter += `min_height=${formatHeight(heightSliderValues[0])}&max_height=${formatHeight(heightSliderValues[1])}&`;

    




    // Append selected age range to the URL filter
      urlFilter += `min_age=${ageSliderValues[0]}&max_age=${ageSliderValues[1]}&`;
      if (selectedLocations.length > 0) {
        urlFilter += `locations=${selectedLocations.join(',')}&`;
    }

    // Remove the trailing '&' if it exists
    if (urlFilter.endsWith('&')) {
        urlFilter = urlFilter.slice(0, -1);
    }

    onApplyFilters(urlFilter);
};


 

 
  
  const [heightSliderValues, setHeightSliderValues] = useState([4, 6]);
  
  // Initial start and end values for age slider
  const [ageSliderValues, setAgeSliderValues] = useState([20, 55]);

  // Handler for height slider value change
  const handleHeightSliderChange = (newValues) => {
    setHeightSliderValues(newValues);
  };

  // Handler for age slider value change
  const handleAgeSliderChange = (newValues) => {
    setAgeSliderValues(newValues);
  };
 
  const handleGenderCheckboxChange = (gender) => {
    setSelectedGenders(prevGenders => {
      if (prevGenders.includes(gender)) {
        return prevGenders.filter(g => g !== gender);
      } else {
        return [...prevGenders, gender];
      }
    });
  };
  
  const handleLanguageCheckboxChange = (language) => {
    setSelectedLanguages(prevLanguages => {
      if (prevLanguages.includes(language)) {
        return prevLanguages.filter(lang => lang !== language);
      } else {
        return [...prevLanguages, language];
      }
    });
  };
  const handleSkillChange = (skill) => {
    setSelectedSkills((prevSelectedSkills) => {
      if (prevSelectedSkills.includes(skill)) {
        // Remove the skill if it's already selected
        return prevSelectedSkills.filter(s => s !== skill);
      } else {
        // Add the skill if it's not already selected
        return [...prevSelectedSkills, skill];
      }
    });
  };
  const handleInterestCheckboxChange = (interest) => {
    setSelectedInterests(prevSelectedInterests => {
        if (prevSelectedInterests.includes(interest)) {
            // Remove interest if it's already selected
            return prevSelectedInterests.filter(i => i !== interest);
        } else {
            // Add interest if it's not already selected
            return [...prevSelectedInterests, interest];
        }
    });
};

const handleEthnicityCheckboxChange = (ethnicity) => {
    setSelectedEthnicities(prevSelectedEthnicities => {
        if (prevSelectedEthnicities.includes(ethnicity)) {
            // Remove ethnicity if it's already selected
            return prevSelectedEthnicities.filter(e => e !== ethnicity);
        } else {
            // Add ethnicity if it's not already selected
            return [...prevSelectedEthnicities, ethnicity];
        }
    });
};
const handleLocationCheckboxChange = (location) => {
  setSelectedLocations(prevSelectedLocations => {
    if (prevSelectedLocations.includes(location)) {
      return prevSelectedLocations.filter(loc => loc !== location);
    } else {
      return [...prevSelectedLocations, location];
    }
  });
};

  
  

  return (
    <div className='sidebar-container'>
  
     <div className=''>
        <h5>HEIGHT</h5>
        <div className=''>
            {/* <p className='my-3 div-gray py-2 text-center' >From:<strong>{sliderValues1[0]}'0"</strong></p>
            <p className='my-3 div-gray py-2 text-center' >Up to:<strong>{sliderValues1[1]}'0"</strong></p> */}
            <p className='my-3 div-gray py-2 text-center'>From:<strong>{heightSliderValues[0]}'0"</strong></p>
          <p className='my-3 div-gray py-2 text-center'>Up to:<strong>{heightSliderValues[1]}'0"</strong></p>
         </div>
     </div>
     {/* <DualSlider minValue={0} maxValue={8}  onChange={handleSliderChange1} /> */}
     <DualSlider minValue={0} maxValue={10} values={heightSliderValues} onChange={handleHeightSliderChange} />
      
     <div  className='my-2 '>
     
     
    </div>

     <div>
        <h5>AGE</h5>
        <div className=''>
            {/* <p className='my-3 div-gray py-2 text-center'>From:<strong>{sliderValues[0]}Yrs</strong></p>
            <p className='my-3 div-gray py-2 text-center'>Up to:<strong>{sliderValues[1]}Yrs</strong></p> */}
            <p className='my-3 div-gray py-2 text-center'>From:<strong>{ageSliderValues[0]}Yrs</strong></p>
          <p className='my-3 div-gray py-2 text-center'>Up to:<strong>{ageSliderValues[1]}Yrs</strong></p>
        </div>
     </div>
     {/* <DualSlider minValue={0} maxValue={60} onChange={handleSliderChange}  /> */}
     <DualSlider minValue={0} maxValue={100} values={ageSliderValues} onChange={handleAgeSliderChange} />
      
     <div  className='my-3'>
        <h5>GENDER</h5>
        <Form className='my-3 scrollable-div sidebar-scroll'  >
        <Form.Check 
            type="checkbox"
            id="one"
            label="Male"
            className="custom-checkbox"
            onChange={() => handleGenderCheckboxChange("M")}
            
           
          />
           <Form.Check 
            type="checkbox"
            id="two"
            label="Female"
            onChange={() => handleGenderCheckboxChange("F")}
            
          />
           <Form.Check 
            type="checkbox"
            id="three"
            label="Child-Boy"
          />
          <Form.Check 
            type="checkbox"
            id="four"
            label="Child-Girl"
          />
          <Form.Check 
            type="checkbox"
            id="five"
            label="Others"
          />
           
        </Form>
        
     
     </div>
     <div className='my-3' >
        <h5>ETHNICITY</h5>
        <Form className='my-3 scrollable-div sidebar-scroll'>
                <Form.Check 
                    type="checkbox"
                    id="checkbox-asian"
                    label="Asian"
                    onChange={() => handleEthnicityCheckboxChange("Asian")}
                    checked={selectedEthnicities.includes("Asian")}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-indian"
                    label="Indian"
                    onChange={() => handleEthnicityCheckboxChange("Indian")}
                    checked={selectedEthnicities.includes("Indian")}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-nepalish"
                    label="Nepalish"
                    onChange={() => handleEthnicityCheckboxChange("Nepalish")}
                   
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-chinese"
                    label="Chinese"
                    onChange={() => handleEthnicityCheckboxChange("Chinese")}
                   
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-japanese"
                    label="Japanese"
                    onChange={() => handleEthnicityCheckboxChange("Japanese")}
                    checked={selectedEthnicities.includes("Japanese")}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-spanish"
                    label="Spanish"
                    onChange={() => handleEthnicityCheckboxChange("Spanish")}
                   
                />
            </Form>
        
     
     </div>
     <div className='my-3'>
        <h5>LOCATION</h5>
        <Form className='my-3 scrollable-div sidebar-scroll'>
          <Form.Check 
            type="checkbox"
            id="chennai"
            label="Chennai"
            onChange={() => handleLocationCheckboxChange("Chennai")}
          />
          <Form.Check 
            type="checkbox"
            id="hyderabad"
            label="Hyderabad"
            onChange={() => handleLocationCheckboxChange("Hyderabad")}
          />
          <Form.Check 
            type="checkbox"
            id="bangalore"
            label="Bangalore"
            onChange={() => handleLocationCheckboxChange("Bangalore")}
          />
          <Form.Check 
            type="checkbox"
            id="mumbai"
            label="Mumbai"
            onChange={() => handleLocationCheckboxChange("Mumbai")}
          />
          <Form.Check 
            type="checkbox"
            id="thiruvananthapuram"
            label="Thiruvananthapuram"
            onChange={() => handleLocationCheckboxChange("Thiruvananthapuram")}
          />
        </Form>
        
     
     </div>
     <div className='my-3'>
        <h5>SKILLS</h5>
        <Form className='my-3 scrollable-div sidebar-scroll'>
                <Form.Check 
                    type="checkbox"
                    id="checkbox-dancing"
                    label="Dancing"
                    onChange={(e) => handleSkillChange("Dancing", e.target.checked)}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-singing"
                    label="Singing"
                    onChange={(e) => handleSkillChange("Singing", e.target.checked)}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-acting"
                    label="Acting"
                    onChange={(e) => handleSkillChange("Acting", e.target.checked)}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-modeling"
                    label="Modeling"
                    onChange={(e) => handleSkillChange("Modeling", e.target.checked)}
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-comedian"
                    label="Comedian"
                    onChange={(e) => handleSkillChange("Comedian", e.target.checked)}
                />
            </Form>
        
     
     </div>
     <div className='my-3 '>
        <h5>INTEREST</h5>
        <Form className='my-3 scrollable-div sidebar-scroll'>
                <Form.Check 
                    type="checkbox"
                    id="checkbox-ad-films"
                    label="Ad Films"
                    onChange={() => handleInterestCheckboxChange("Ad Films")}
                    
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-movies"
                    label="Movies"
                    onChange={() => handleInterestCheckboxChange("Movies")}
                   
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-web-series"
                    label="Web Series"
                    onChange={() => handleInterestCheckboxChange("Web Series")}
                   
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-tv-serials"
                    label="TV Serials"
                    onChange={() => handleInterestCheckboxChange("TV Serials")}
                    
                />
                <Form.Check 
                    type="checkbox"
                    id="checkbox-fashion-show"
                    label="Fashion Show"
                    onChange={() => handleInterestCheckboxChange("Fashion Show")}
                   
                />
            </Form>
        
     
     </div>
     <div className='my-3'>
        <h5>LANGUAGE</h5>
        <Form className='my-3 scrollable-div sidebar-scroll'  >
        <Form.Check 
            type="checkbox"
            id="one"
            label="Hindi"
            onChange={() => handleLanguageCheckboxChange("Hindi")}
          />
            <Form.Check 
            type="checkbox"
            id="English"
            label="English"
            onChange={() => handleLanguageCheckboxChange("English")}
          />
           <Form.Check 
            type="checkbox"
            id="Telugu"
            label="Indian"
            onChange={() => handleLanguageCheckboxChange("Indian")}
          />
           <Form.Check 
            type="checkbox"
            id="three"
            label="Tamil"
            onChange={() => handleLanguageCheckboxChange("Tamil")}
          />
          <Form.Check 
            type="checkbox"
            id="four"
            label="Malayalam"
            onChange={() => handleLanguageCheckboxChange("Malayalam")}
          />
          <Form.Check 
            type="checkbox"
            id="five"
            label="Marathi"
            onChange={() => handleLanguageCheckboxChange("Marathi")}
          />
        </Form>
        
     
     </div>
     <Button variant=" " onClick={handleApplyFilters} className="btn-sm btn-md-block pale-green text-white my-4 w-100"  ><span className='mx-3'>Apply Filters</span></Button>{' '}
    
    </div>
  );
}

export default Sidebar;
