import React, { useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ImageCard from '../Cards/ImageCard';
import Sidebar from '../SideBar/Sidebar';
import '../SideBar/Sidebar.css';

// import { Filter } from 'react-bootstrap-icons';

const MainContainer = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  // const [filter, setFilter] = useState({});

  // const handleApplyFilters = (newFilter) => {
  //   setFilter(newFilter);
  // };
  const [urlFilter, setUrlFilter] = useState('');
  const handleApplyFilters = (urlFilter) => {
    setUrlFilter(urlFilter);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  // const toggleSidebar = () => {
  //   setShowSidebar(true); // Set the state to true to make the sidebar visible
  // };
  // const handleApplyFilters = (filteredData) => {
  //   setFilteredData(filteredData);
  // };

  return (
    <div className='mt-2'>
      <Container>
        <Row>
          <Col
            xs={6}
            sm={3}
            md={3}
            className='sidebar-hidden'
            style={{
              left: showSidebar ? '0' : '-100%',
              transition: 'left 0.3s',
              zIndex: 2,
              marginTop: showSidebar ? '40px' : '40px',
            }}
          >
            <Sidebar onApplyFilters={handleApplyFilters} />
          </Col>

          {/* Button to toggle sidebar visibility */}
          {/* <Col xs={12} sm={12} className='d-block d-sm-none'>
            <Button className='text-end' style={{background:'#fff',color:"#333",textAlign:'right'}} onClick={toggleSidebar}><i class="fa-solid fa-filter"></i></Button>
          </Col> */}

          {/* <Col xs={5} sm={5} md={3} className={showSidebar ? '' : 'd-none d-sm-block'}>
            <Sidebar />
          </Col> */}

          <Col
            xs={12}
            sm={9}
            md={9}
            lg={9}
            className=''
            style={{ borderLeft: '1px solid grey' }}
          >
            <div className='w-100 d-flex align-items-center justify-content-sm-center justify-content-md-between justify-content-lg-between gap-3'>
              <div>
                <h6 className=''>Top Talents</h6>
              </div>

              {/* <div className='d-flex'>
                <Col xs={12} sm={12} className='d-block d-sm-none'>
            <Button className='text-end' style={{background:'#fff',color:"#333",textAlign:'right'}} onClick={toggleSidebar}><i class="fa-solid fa-filter"></i></Button>
          </Col>
                <Form.Select aria-label="Default select example" className='w-100'>
                <option>Popular</option>
                <option value="1">Recent</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
    </Form.Select>
                </div> */}
              <div
                className='d-flex  align-items-center  justify-content-end'
                style={{ width: '85%' }}
              >
                <Button
                  className='text-end d-block d-sm-none border-0'
                  style={{
                    background: '#fff',
                    color: '#333',
                    textAlign: 'right',
                  }}
                  onClick={toggleSidebar}
                >
                  <i className='fa-solid fa-filter'></i>
                </Button>
                <Form.Select
                  aria-label='Default select example'
                  className='w-50'
                >
                  <option>Popular</option>
                  <option value='1'>Recent</option>
                  <option value='2'>Two</option>
                  <option value='3'>Three</option>
                </Form.Select>
              </div>
              {/* <Form.Select aria-label="Default select example" className='w-50'>
                <option>Popular</option>
                <option value="1">Recent</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
    </Form.Select> */}
            </div>
            <div>
              <ImageCard urlFilter={urlFilter} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainContainer;
