import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../GlobalUtil';
import { useCookies } from '../../utils/useCookies';
import AvatarDropdown from './AvatarDropdown';
import MyProjectDropdown from './MyProjectDropdown';
// import {logoutCall} from '../../api'

const Navbarresp = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [menuList, setMenuList] = useState('talents');
  const { getCookie, deleteCookie } = useCookies();
  const userType = localStorage.getItem('userType');

  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    // const token = getCookie('auth_token');
    // const logoutStatus = await logoutCall(token);
    // if (logoutStatus.status === 200) {
    //   deleteCookie('auth_token');
    //   deleteCookie('sessionid');
    //   deleteCookie('csrftoken');
    //   deleteCookie('user_id');
    //   deleteCookie('user_type');
    //   deleteCookie('status');
    //   localStorage.removeItem('authToken');
    //   localStorage.removeItem('user_id');
    //   localStorage.removeItem('userType');
    //   setLoggedIn(false);
    //   setMenuList('login');
    // }
    setLoggedIn(false);
    setMenuList('login');
    console.log('logout....');
  };

  const handleDomainRedirect = (url) => {
    if (url === 'casting') {
      window.location.href = 'https://dev.chamcast.com';
    } else {
      window.location.href = `https://dev.chamcast.com/${url}`;
    }
  };

  useEffect(() => {
    if (menuList !== 'talents') {
      handleDomainRedirect(menuList);
    }
  }, [menuList]);

  useEffect(() => {
    localStorage.setItem('userType', getCookie('user_type'));
    let authToken = getCookie('auth_token');
    if (authToken === null) {
      authToken = localStorage.getItem('authToken');
    }
    if (authToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='mb-4 shadow'>
      <Container fluid='xxl'>
        <Navbar expand='lg' className='bg-white px-2 py-3'>
          <Navbar.Brand href={BASE_URL}>
            <img
              src='images/logo.png'
              alt='chamcast logo'
              className='img-fluid'
              style={{ maxWidth: '200px', height: 'auto', maxHeight: '50px' }}
            />
          </Navbar.Brand>
          <div className='d-flex align-items-center'>
            <AvatarDropdown
              className='d-inline d-lg-none mx-1 px-2'
              userType={userType}
              handleLogout={handleLogout}
            />
            <Navbar.Toggle
              aria-controls='basic-navbar-nav'
              className='border-0'
            >
              <i
                className='fa fa-bars'
                style={{ border: 'none', color: 'black' }}
              />
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto mx-auto' defaultActiveKey='/talent'>
              <Nav.Link className='px-3' onClick={() => setMenuList('casting')}>
                Casting
              </Nav.Link>
              {userType === 'HR' && (
                <Nav.Link href='/talent' className='px-3'>
                  Talent
                </Nav.Link>
              )}
              <Nav.Link
                className='px-3'
                onClick={() => setMenuList('services')}
              >
                Services
              </Nav.Link>
              <Nav.Link
                className='px-3'
                onClick={() => setMenuList('about-us')}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className='px-3'
                onClick={() => setMenuList('contact-us')}
              >
                Contact Us
              </Nav.Link>
            </Nav>
            {userType === 'HR' && <MyProjectDropdown />}
            {loggedIn ? (
              <AvatarDropdown
                className='d-none d-lg-inline mx-3 px-2'
                userType={userType}
                handleLogout={handleLogout}
              />
            ) : (
              <Button
                className='login-btn btn-gradient'
                style={{ outline: 'none', border: 'none' }}
                onClick={handleLoginClick}
              >
                LOGIN | REGISTER
              </Button>
            )}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Navbarresp;
