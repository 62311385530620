import React, { useState } from 'react';
import { Button, Card, Col, Form, Toast, ToastContainer } from 'react-bootstrap';
import Navbarresp from '../Navbar/navbar';
import axios from 'axios'; 
import { BASE_URL } from '../../GlobalUtil';
import { Link } from 'react-router-dom';

const CreateProject = () => {
 
  const [name, setName] = useState("");
  const [description, setDescription] = useState("")
  const [image, setImage] = useState(null); 
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] =useState(null)


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0])); 
    }
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${BASE_URL}/api/projects/`;
   const token = localStorage.getItem('authToken');
   const formData = {
    name,
    description
   }

    axios.post(url, formData
        , {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
      .then(() => {
        window.location.href = '/myprojects';
        
      })
      .catch((error) => {
        setShow(true)
        setErrorMessage(error.response.statusText)
        // console.error('Error sending data:', error.response.statusText);
        setTimeout(() => {
          setShow(false);
          setErrorMessage(null)
        }, 3000);
      });
  };

  return (
    <>
      <Navbarresp showLoginButton={false} showShortlistButton={true} />
<Col lg={8} sm={12} className='mx-auto'>
      <Card className="mx-auto mt-4 popup-shadow" >
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <h2 className="text-center mt-4">Create a New Project</h2>
            <Form.Group controlId="name">
              <Form.Label>Project Title*</Form.Label>
              <Form.Control
              required
                type="text"
                placeholder="Enter project title"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="description" className='mt-3'>
              <Form.Label>Project Description*</Form.Label>
              <Form.Control
              required
              placeholder="Enter Project Description"

                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleImageChange(e);
                }}
                style={{
                  width: '100%',
                  height: '200px',
                  border: '2px dashed #ccc',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginTop: '20px'
                }}
              >
                <Form.Group controlId="formFileLg" className="mb-3">
              <Form.Label>Drag & Drop Image Here</Form.Label>
              <Form.Control type="file" size="lg"  />
            </Form.Group>
                {
                // image? <img src={image} alt="Preview" style={{maxWidth: '100%', maxHeight: '150px'}} />: 
                // "Drag & Drop Image Here"
                 }
              </div>


            {/* <Form.Group controlId="formFileLg" className="mb-3">
              <Form.Label>Drag & Drop Image Here</Form.Label>
              <Form.Control type="file" size="lg"  />
            </Form.Group> */}
            

            <div className="d-flex justify-content-between px-3 pb-3">
        <Link to='/myprojects' style={{textDecoration:"none",color:'black'}}>

              <Button className='mt-3' variant="danger" size="sm">Cancel</Button>
              </Link>
              <Button className='mt-3' type="submit" variant="primary" size="sm">Create</Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      </Col>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
      <Toast className="bg-danger" onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Something went wrong</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>{errorMessage}</Toast.Body>
        </Toast>
        </ToastContainer>
    </>
  );
}

export default CreateProject;
