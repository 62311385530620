import { useRef } from 'react';
import { Form } from 'react-bootstrap';

const Checkbox = ({ label, onClick,isChecked  }) => {
  const checkboxRef = useRef(null);

  return (
    
    <div
      className={`border p-2 rounded w-25 ${isChecked ? 'bg-success-subtle border border-success' : ''}`}
    >
      <Form.Check
        className='mb-0'
        label={label}
        name={label}
        type='checkbox'
        id={`id-${label}`}
        value={label}
        onChange={onClick}
        checked={isChecked}
        ref={checkboxRef}
      />
    </div>
  );
};

export default Checkbox;
