import React, { useEffect, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Image,
  Nav,
  Row,
  Stack,
  Tab,
  Toast,
  ToastContainer
} from 'react-bootstrap';
import Navbarresp from '../Navbar/navbar';
import ProfileSidebar from './ProfileSidebar';

import axios from 'axios';
import { X } from 'react-bootstrap-icons';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { BASE_URL } from '../../GlobalUtil';

import Footer from '../Footer/Footer';
import Upload from '../Upload/Upload';
import Checkbox from '../Checkbox/Checkbox';


const ProfileEdit = () => {
  const [talentName, setTalentName] = useState('');
  const [talent_image, setTalentImage] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [bio, setBio] = useState('');
  const [eyeColor, setEyeColor] = useState('');
  const [skinTone, setSkinTone] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [hairColor, setHairColor] = useState('');
  // const [height, setHeight] = useState("6'0");
  const [height, setHeight] = useState('6’');
  const [weight, setWeight] = useState(85);
  const [languages, setLanguages] = useState([]);
  const [interests, setInterests] = useState([]);
  const [skills, setSkills] = useState([]);
  const [auditionLocation, setAuditionLocation] = useState([]);
  const [newLanguage, setNewLanguage] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [unit, setUnit] = useState('cm');
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  // const [updatedprofilepicture,setUpdatedProfilePicture]=useState('');

  //arun_dev

  const fileInputRef = useRef(null);
  const showReelInputRef = useRef(null);
  const portfolioInputRef = useRef(null);
  const [profileImage, setProfileImage] = useState('');
  const [reelsImage, setReelsImage] = useState([]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(URL.createObjectURL(file));
  };

  const handleShowReelsClick = () => {
    showReelInputRef.current.click();
  };

  const handleShowReelsFileChange = (e) => {
    const file = e.target.files[0];
    setReelsImage([...reelsImage, URL.createObjectURL(file)]);
    console.log(reelsImage);
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...reelsImage];
    updatedFiles.splice(index, 1);
    setReelsImage(updatedFiles);
  };
  //arun_dev

  const decreaseWeight = () => {
    if (weight > 0) {
      setWeight(weight - 1);
    }
  };

  const increaseWeight = () => {
    setWeight(weight + 1);
  };

  const cmToFeetInches = (cm) => {
    const totalInches = cm * 0.393701;
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);
    return `${feet}'${inches}"`;
  };

  const handleUnitChange = (selectedUnit) => {
    setUnit(selectedUnit);
    if (selectedUnit === 'ft') {
      const [feet, inches] = cmToFeetInches(parseInt(height)).split("'");
      const formattedHeight = `${feet}'${inches}"`;
      setHeight(formattedHeight);
    } else {
      let [feet, inches] = height.split("'");
      inches = inches.replace(/[^0-9]/g, '');
      const totalInches = parseInt(feet) * 12 + parseInt(inches);
      const heightInCm = Math.round(totalInches * 2.54);
      setHeight(heightInCm.toString());
    }
  };

  const decreaseHeight = () => {
    if (unit === 'cm') {
      setHeight((prevHeight) => (parseInt(prevHeight) - 1).toString());
    } else {
      let [feet, inches] = height.split("'");
      inches = inches.replace(/[^0-9]/g, '');
      let newFeet = parseInt(feet);
      let newInches = parseInt(inches);
      if (newInches > 0) {
        newInches--;
      } else if (newFeet > 0) {
        newFeet--;
        newInches = 11;
      }
      setHeight(`${newFeet}'${newInches}"`);
    }
  };

  const increaseHeight = () => {
    if (unit === 'cm') {
      setHeight((prevHeight) => (parseInt(prevHeight) + 1).toString());
    } else {
      let [feet, inches] = height.split("'");
      inches = inches.replace(/[^0-9]/g, '');
      let newFeet = parseInt(feet);
      let newInches = parseInt(inches);
      if (newInches < 11) {
        newInches++;
      } else {
        newFeet++;
        newInches = 0;
      }
      setHeight(`${newFeet}'${newInches}"`);
    }
  };
  const interestArray = [
    'Movies',
    'TV Shows',
    'Ad Films',
    'Web Series',
    'Modeling',
    'Fashion Show'
  ];


  const handleInterestChange = (e) => {
    const { value, checked } = e.target;
    setInterests((prevInterests) => {
      if (checked) {
        return [...prevInterests, value];
      } else {
        return prevInterests.filter((interest) => interest !== value);
      }
    });
  };
  const isChecked = (interest) => {
    
    if (interests) {
      return interests.includes(interest);
    }
    return false; 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const user_id = localStorage.getItem('user_id');
        console.log('Fetching data for user_id:', user_id); // Debug log

        // const response = await axios.get(`${BASE_URL}/api/talent/profile/${user_id}`, {
        const response = await axios.get(`${BASE_URL}/api/talent/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        const profileData = response.data;
        console.log('Fetched profile data:', profileData); // Debug log

        // Update state with profile data
        setTalentName(profileData.talent_name);
        setYearsOfExperience(profileData.year_of_experience);
        setDob(profileData.dob);
        setGender(profileData.gender);
        setBio(profileData.bio);
        setEyeColor(profileData.eye_color);
        setSkinTone(profileData.skin_tone);
        setEthnicity(profileData.ethnicity);
        setHairColor(profileData.hair_color);
        setHeight(profileData.height);
        setWeight(profileData.weight);
        setLanguages(profileData.languages);
        setInterests(profileData.interests);
        setSkills(profileData.skills);
        setAuditionLocation(profileData.audition_location);

        if (profileData.talent_image && profileData.talent_image.length > 0) {
          setTalentImage(profileData.talent_image[0].file);
        }

        setIsLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setIsLoading(false); // Ensure loading is set to false even if there's an error
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      // const userType=localStorage.getItem('userType');
      // const user_id=localStorage.getItem('user_id');

      const formData = {
        talent_name: talentName,
        talent_image: [{ file: talent_image }],
        year_of_experience: yearsOfExperience,
        dob: dob,
        gender: gender,
        bio: bio,
        eye_color: eyeColor,
        skin_tone: skinTone,
        ethnicity: ethnicity,
        hair_color: hairColor,
        height: height,
        weight: weight,
        languages: languages,
        interests: interests,
        skills: skills,
        audition_location: auditionLocation
      };

      // const response = await axios.put(`${BASE_URL}/api/talent/profile/${user_id}`, formData);
      // const response = await axios.put(`${BASE_URL}/editProfile`, formData);
      const response = await axios.put(
        // `${BASE_URL}/api/talent/profile/${user_id}`,
        `${BASE_URL}/api/talent/profile/`,

        formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('Response:', response.data);
      // Assuming the response contains the updated profile data
      const updatedProfileData = response.data; // Adjust this according to your response structure

      // Update state with the received data
      setTalentName(updatedProfileData.talent_name);
      setTalentImage(updatedProfileData.talent_image);
      setYearsOfExperience(updatedProfileData.year_of_experience);
      setDob(updatedProfileData.dob);
      setGender(updatedProfileData.gender);
      setBio(updatedProfileData.bio);
      setEyeColor(updatedProfileData.eye_color);
      setSkinTone(updatedProfileData.skin_tone);
      setEthnicity(updatedProfileData.ethnicity);
      setHairColor(updatedProfileData.hair_color);
      setHeight(updatedProfileData.height);
      setWeight(updatedProfileData.weight);
      setLanguages(updatedProfileData.languages);
      setInterests(updatedProfileData.interest);
      setSkills(updatedProfileData.skills);
      setAuditionLocation(updatedProfileData.audition_location);
      setShowToast(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSkillsChange = (e) => {
    if (e.key === 'Enter' && e.target.value.trim() !== '') {
      const skill = e.target.value.trim();
      if (!skills.includes(skill)) {
        setSkills([...skills, skill]);
      }
      e.target.value = '';
    }
  };

  const removeSkill = (skillToRemove) => {
    setSkills(skills.filter((skill) => skill !== skillToRemove));
  };

  const handleLanguagesChange = (e) => {
    const selectedLanguage = e.target.value;
    if (selectedLanguage && !languages.includes(selectedLanguage)) {
      setLanguages([...languages, selectedLanguage]);
      setNewLanguage(''); 
    }
  };

  const removeLanguages = (languageToRemove) => {
    const updatedLanguages = languages.filter(language => language !== languageToRemove);
    setLanguages(updatedLanguages);
  };

  const handleLocationChange=(e)=>{
    const selectedLocation = e.target.value;
    if (selectedLocation && !auditionLocation.includes(selectedLocation)) {
      setAuditionLocation([...auditionLocation, selectedLocation]);
      setNewLocation(''); 
    }
  }
  const removeLocation=(locationtoRemove)=>{
    setAuditionLocation(auditionLocation.filter(location=>location !== locationtoRemove))
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  // const isChecked = (interest) => interests.includes(interest);
  

  return (
    <div>
      <Navbarresp showLoginButton={false} showShortlistButton={true} />
      <Container fluid='xxl'>
        <Row>
          <Col xs={12} sm={6} md={3} lg={3} xl={3}>
            <ProfileSidebar />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xl={3} className='chamcast-profile-edit'>
            <section className='mt-4'>
              <ToastContainer position='top-end' className='p-3'>
                <Toast
                  onClose={() => setShowToast(false)}
                  show={showToast}
                  delay={3000}
                  autohide
                >
                  <Toast.Header>
                    <strong className='me-auto'>Profile Update</strong>
                  </Toast.Header>
                  <Toast.Body>Profile updated successfully!</Toast.Body>
                </Toast>
              </ToastContainer>

              <div className=''>
                <h4>Edit Profile</h4>
              </div>
              <div className='d-flex flex-column align-items-center gap-2'>
                {profileImage ? (
                  <Image
                    src={profileImage}
                    className='img-border-radius'
                    alt='profile image'
                    rounded
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  <Image
                    src='/images/noAvatar.png'
                    className='w-50 h-50'
                    rounded
                  />
                )}
                <div>
                  <Form.Control
                    type='file'
                    className='d-none'
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button variant='dark' onClick={handleButtonClick}>
                    Edit / Upload Photo
                  </Button>
                </div>
              </div>
              <Tab.Container defaultActiveKey='edit_portfolio'>
                <Row className='mt-3'>
                  <Col sm={12}>
                    <Nav
                      className='justify-content-center border-bottom border-secondary-subtle gap-5'
                      variant='underline'
                    >
                      <Nav.Item>
                        <Nav.Link eventKey='edit_portfolio' className='px-1'>
                          Portfolio
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='edit_show_reels' className='px-1'>
                          Show-reels
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className='mt-2'>
                      <Tab.Pane eventKey='edit_portfolio'>
                        <div style={{ opacity: '50.24%' }}>
                          Keep your portfolio updated this photos will be shared
                          with production team
                        </div>
                        <div className='d-flex flex-wrap mt-2 gap-2'>
                          <Upload />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey='edit_show_reels'>
                        <div style={{ opacity: '50.24%' }}>
                          Upload your latest reels so that people will know who
                          you are
                        </div>
                        <div className='mt-2 d-flex flex-wrap gap-2'>
                          <Upload />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </section>
          </Col>

          <Col xs={12} sm={12} md={5} lg={6} xl={6}>
            <div
              className=' mt-4 text-end d-flex justify-content-end'
              style={{ textAlign: 'right !important' }}
            >
              <Button
                type='submit'
                className='outline-none border-0'
                onClick={handleSubmit}
                style={{
                  textAlign: 'right',
                  backgroundColor: '#b4d876',
                  outline: 'none !important'
                }}
              >
                Save
              </Button>
            </div>

            <Form className='mt-4'>
              <Row className='mb-3'>
                <Form.Group as={Col} xl={6} lg={6} controlId='talent_name'>
                  <Form.Label>Talent Name *</Form.Label>
                  <Form.Control
                    value={talentName}
                    onChange={(e) => setTalentName(e.target.value)}
                    type='text'
                    placeholder='Enter your name...'
                  />
                </Form.Group>
                <Form.Group as={Col} xl={6} lg={6} controlId='talent_exp'>
                  <Form.Label>Total years of experience *</Form.Label>
                  <Form.Select
                    value={yearsOfExperience}
                    onChange={(e) => setYearsOfExperience(e.target.value)}
                  >
                    <option value='1'>1 Yrs</option>
                    <option value='2'>2 Yrs</option>
                    <option value='3'>3 Yrs</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              <Form.Group className='mb-3' controlId='talent_desc'>
                <Form.Label>Bio</Form.Label>
                <Form.Control
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  as='textarea'
                  rows={3}
                />
              </Form.Group>
              <Row className='mb-3'>
                <Form.Group as={Col} xl={6} lg={6} controlId='talent_dob'>
                  <Form.Label>Date of Birth *</Form.Label>
                  <Form.Control
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    type='date'
                  />
                </Form.Group>
                <Form.Group as={Col} xl={6} lg={6} controlId='talent_gender'>
                  <Form.Label>Gender *</Form.Label>
                  <Form.Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    aria-label='select your gender'
                  >
                    <option value='M'>Male</option>
                    <option value='F'>Female</option>
                    <option value='O'>Others</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              {/* <Form.Label>Select your interests *</Form.Label>
              {['check'].map((type, index) => (
                <div
                  key={index}
                  className='mb-3 gap-3 d-flex flex-wrap align-items-center '
                >
                  <div
                    className='border p-2 rounded'
                    style={{
                      backgroundColor: isChecked('Movies')
                        ? '#e5f2cf'
                        : 'transparent'
                    }}
                  >
                    <Form.Check
                      className='mb-0'
                      label='Movies'
                      name='group1'
                      type='checkbox'
                      id={`inline-${type}-1`}
                      value='Movies'
                      onChange={handleInterestChange}
                      // checked={interests.includes("Movies")}
                      checked={isChecked('Movies')}
                    />
                  </div>
                  <div
                    className='border p-2 '
                    style={{
                      backgroundColor: isChecked('TV Shows')
                        ? '#e5f2cf'
                        : 'transparent'
                    }}
                  >
                    <Form.Check
                      label='TV Shows'
                      name='group1'
                      type='checkbox'
                      id={`inline-${type}-2`}
                      value='TV Shows'
                      onChange={handleInterestChange}
                      // checked={interests.includes("TV Shows")}
                      checked={isChecked('TV Shows')}
                    />
                  </div>
                  <div
                    className='border p-2 '
                    style={{
                      backgroundColor: isChecked('Ad Films')
                        ? '#e5f2cf'
                        : 'transparent'
                    }}
                  >
                    <Form.Check
                      label='Ad Films'
                      name='group1'
                      type='checkbox'
                      id={`inline-${type}-3`}
                      value='Ad Films'
                      onChange={handleInterestChange}
                      // checked={interests.includes("Ad Films")}
                      checked={isChecked('Ad Films')}
                    />
                  </div>
                  <div
                    className='border p-2 '
                    style={{
                      backgroundColor: isChecked('Web Series')
                        ? '#e5f2cf'
                        : 'transparent'
                    }}
                  >
                    <Form.Check
                      label='Web Series'
                      name='group1'
                      type='checkbox'
                      id={`inline-${type}-4`}
                      value='Web Series'
                      onChange={handleInterestChange}
                      //  checked={interests.includes("Web Series")}
                      checked={isChecked('Web Series')}
                    />
                  </div>
                  <div
                    className='border p-2 '
                    style={{
                      backgroundColor: isChecked('Modeling')
                        ? '#e5f2cf'
                        : 'transparent'
                    }}
                  >
                    <Form.Check
                      inline
                      label='Modeling'
                      name='group1'
                      type='checkbox'
                      id={`inline-${type}-5`}
                      value='Modeling'
                      onChange={handleInterestChange}
                      //  checked={interests.includes("Modeling")}
                      checked={isChecked('Modeling')}
                    />
                  </div>
                  <div
                    className='border p-2 '
                    style={{
                      backgroundColor: isChecked('Fashion Show')
                        ? '#e5f2cf'
                        : 'transparent'
                    }}
                  >
                    <Form.Check
                      inline
                      label='Fashion Show'
                      name='group1'
                      type='checkbox'
                      id={`inline-${type}-6`}
                      value='Fashion Show'
                      onChange={handleInterestChange}
                      // checked={interests.includes("Fashion Show")}
                      checked={isChecked('Fashion Show')}
                    />
                  </div>
                </div>
              ))} */}



        <Form.Label>Select your interests  *</Form.Label>
      {['check'].map(( index) => (
        <div
          key={index}
          className='mb-3 gap-3 d-flex flex-wrap align-items-center'
        >
          {interestArray.map((interest, idx) => (
            <Checkbox
              key={idx}
              label={interest}
              onClick={handleInterestChange}
              isChecked={isChecked(interest)}
            />
          ))}
        </div>
      ))}
           
    
              {/* </Form> */}

              <Form.Group className='my-3' controlId='formBasicEmail'>
                <Form.Label>Add Skills *</Form.Label>
                
                <Form.Control
                  type='text'
                  placeholder='Add your skills'
                  // onChange={handleSkillsChange}
                  onKeyDown={handleSkillsChange}
                />

                {/* Display the skills badges */}
                <Stack
                  direction='horizontal'
                  gap={2}
                  className='d-flex flex-wrap my-2'
                >
                 
                  {skills.map((skill, index) =>
                    skill.split(',').map((s, idx) => (
                      <Badge
                        key={index + '-' + idx}
                        pill
                        className='px-4 py-2 badge-bg d-flex align-items-center bg-success-subtle'
                      >
                        <p className='m-0'>
                          {s.trim()}{' '}
                          <span
                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                            onClick={() => removeSkill(s.trim())}
                            className='text-danger'
                          >
                            <X />
                          </span>
                        </p>
                      </Badge>
                    ))
                  )}
                </Stack>

                <Row>
                  <Col lg={12} xl={6}>
                    <Form.Group>
                      <div className='d-flex align-items-center mt-4 gap-4'>
                        <Form.Label>Height *</Form.Label>
                        <ButtonToolbar aria-label='Toolbar with button groups'>
                          <ButtonGroup className='' aria-label='First group'>
                            <Button
                              className=''
                              style={{
                                background: unit === 'ft' ? '#ffcf31' : 'white',
                                color: unit === 'ft' ? '#333' : '#333'
                              }}
                              onClick={() => handleUnitChange('ft')}
                            >
                              FT
                            </Button>
                            <Button
                              className=''
                              style={{
                                background: unit === 'cm' ? '#ffcf31' : 'white',
                                color: unit === 'cm' ? '#333' : '#333'
                              }}
                              onClick={() => handleUnitChange('cm')}
                            >
                              CM
                            </Button>{' '}
                          </ButtonGroup>
                        </ButtonToolbar>
                      </div>

                      <div className='d-flex justify-content-around mt-3 gap-2 align-items-center text-muted '>
                        <Button
                          style={{ color: 'black', background: 'white' }}
                          className='border rounded-circle px-4 py-2 m-0'
                          onClick={decreaseHeight}
                        >
                          -
                        </Button>
                        <h5 className='border flex-fill text-center text-muted py-2 m-0'>
                          {height}
                        </h5>
                        <Button
                          style={{ color: 'black', background: 'white' }}
                          className='border rounded-circle px-4 py-2 m-0'
                          onClick={increaseHeight}
                        >
                          +
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12} xl={6}>
                    <Form.Group className=''>
                      <div className='mt-4'>
                        <Form.Label>Weight *</Form.Label>
                      </div>

                      <div className='d-flex justify-content-around gap-2 mt-3 align-items-center text-muted'>
                        <Button
                          className='border rounded-circle px-4 py-2 m-0'
                          style={{ color: 'black', background: 'white' }}
                          onClick={decreaseWeight}
                        >
                          -
                        </Button>
                        <h5 className='border flex-fill text-center text-muted py-2 m-0'>
                          {weight}KG
                        </h5>
                        <Button
                          className='border rounded-circle px-4 py-2 m-0'
                          style={{ color: 'black', background: 'white' }}
                          onClick={increaseWeight}
                        >
                          +
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col lg={6} xl={6}>
                  <Form.Group
                    className='my-2'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label>Eye Colour *</Form.Label>
                    <Form.Select
                      value={eyeColor}
                      onChange={(e) => setEyeColor(e.target.value)}
                      placeholder='Select eye colour'
                    >
                      <option value='' disabled>
                        Select eye colour
                      </option>
                      <option value='Amber'>Amber</option>
                      <option value='Blue'>Blue</option>
                      <option value='Brown'>Brown</option>
                      <option value='Gray'>Gray</option>
                      <option value='Green'>Green</option>
                      <option value='Hazel'>Hazel</option>
                      <option value='Red'>Red</option>
                      <option value='Red'>Black</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6} xl={6}>
                  <Form.Group
                    className='my-2'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label>Skin Tone *</Form.Label>
                    <Form.Select
                      value={skinTone}
                      onChange={(e) => setSkinTone(e.target.value)}
                    >
                      <option value='' disabled>
                        Select skin tone
                      </option>
                      <option value='VF'>
                        Very Fair or Extremely Fair Skin
                      </option>
                      <option value='FS'>Fair Skin</option>
                      <option value='MS'>Medium Skin</option>
                      <option value='LBS'>Olive or Light Brown Skin</option>
                      <option value='BRS'>Brown Skin</option>
                      <option value='BLS'>Black Skin</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={6}>
                  <Form.Group
                    className='my-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label>Primary Ethinicity *</Form.Label>
                    <Form.Select
                      value={ethnicity}
                      onChange={(e) => setEthnicity(e.target.value)}
                    >
                      <option value='' disabled>
                        Select Ethinicity
                      </option>
                      <option value='WH'>White</option>
                      <option value='BAA'>Black or African American</option>
                      <option value='AIAN'>
                        American Indian or Alaska Native
                      </option>
                      <option value='ASIAN'>Asian</option>
                      <option value='HAWAIN'>Native Hawaiian</option>
                      <option value='OTHERPACIFIC'>
                        Other Pacific Islander
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xl={6} lg={6}>
                  <Form.Group
                    className='my-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label>Hair Colour *</Form.Label>
                    <Form.Select
                      value={hairColor}
                      onChange={(e) => setHairColor(e.target.value)}
                    >
                      <option value='' disabled>
                        Select hair colour
                      </option>
                      <option value='BL'>Black</option>
                      <option value='DTBR'>Darkest Brown</option>
                      <option value='DBR'>Dark Brown</option>
                      <option value='BR'>Brown</option>
                      <option value='LBR'>Light Brown</option>
                      <option value='DBL'>Dark Blonde</option>
                      <option value='BLN'>Blonde</option>
                      <option value='LBLN'>Light Blonde</option>
                      <option value='VBLN'>Very Light Blonde</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

           

            <Form.Group className='my-3' controlId='formBasicEmail'>
              <Form.Label>Preferred Language *</Form.Label>
              <Form.Select 
              value={newLanguage}
              onChange={handleLanguagesChange}
             >
              <option value='' disabled>
                Select languages
              </option>
              <option value="English">English</option>
              <option value="French">French</option>
              <option value="Japanese">Japanese</option>
              <option value="Chinese">Chinese</option>
              <option value="Assamese">Assamese</option>
              <option value="Bangla">Bangla</option>
              <option value="Bodo">Bodo</option>
              <option value="Dogri">Dogri</option>
              <option value="Gujarati">Gujarati</option>
              <option value="Hindi">Hindi</option>
              <option value="Kashmiri">Kashmiri</option>
              <option value="Kannada">Kannada</option>
              <option value="Konkani">Konkani</option>
              <option value="Maithili">Maithili</option>
              <option value="Malayalam">Malayalam</option>
              <option value="Manipuri">Manipuri</option>
              <option value="Marathi">Marathi</option>
              <option value="Nepali">Nepali</option>
              <option value="Oriya">Oriya</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Tamil">Tamil</option>
              <option value="Telugu">Telugu</option>
              <option value="Santali">Santali</option>
              <option value="Sindhi">Sindhi</option>
              <option value="Urdu">Urdu</option>

              </Form.Select>

              <Stack
                direction='horizontal'
                gap={2}
                className='d-flex flex-wrap my-2'
              >
               
               {languages.map((language, index) => (
               language.split(',').map((lang, idx) => (
               <Badge key={index + '-' + idx} pill 
               className="px-4 py-2 badge-bg d-flex align-items-center bg-success-subtle">
                <p className="m-0">
                    {lang.trim()} <span className="text-danger"
                    style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={() => removeLanguages(lang.trim())}
                    ><X /></span>
                </p>
            </Badge>
                ))
            ))}
              </Stack>
            </Form.Group>

            <Form.Group className='my-3' controlId='formBasicEmail'>
              <Form.Label>Preferred Location for Audition *</Form.Label>
              <Form.Select
              value={newLocation}
              onChange={handleLocationChange}>
              <option value='' disabled>
                Select Location
              </option>
              <option value="Chennai">Chennai</option>
              <option value="Bangalore">Bangalore</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Chinese">Chinese</option>
              <option value="Kochi">Kochi</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Delhi">Delhi</option>
            
              </Form.Select>


              <Stack
                direction='horizontal'
                gap={2}
                className='d-flex flex-wrap my-2'
              >
                {auditionLocation.map((location, index) => (
               location.split(',').map((loc, idx) => (
               <Badge key={index + '-' + idx} pill 
               className="px-4 py-2 badge-bg d-flex align-items-center bg-success-subtle">
                <p className="m-0">
                    {loc.trim()} <span style={{ cursor: 'pointer', marginLeft: '8px' }}
                     onClick={() => removeLocation(loc.trim())}
                     className="text-danger"><X /></span>
                </p>
            </Badge>
        ))
    ))}
              </Stack>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ProfileEdit;
