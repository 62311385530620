import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../GlobalUtil';
import Footer from '../Footer/Footer';
import Navbarresp from '../Navbar/navbar';
import ProfileSidebar from './ProfileSidebar';




const MyWork = () => {

    const url=`${BASE_URL}/api/myworks/`;
    const[workcard,setWorkCard]=useState('');
   
    
    
  useEffect(()=>{
    const MyWorkCardDetails = async () => {
      
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setWorkCard(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    MyWorkCardDetails();
  },[url])




  return (
    <div>
      <Navbarresp showLoginButton={false} showShortlistButton={true}/>
      <Container>
      <Row>
        <Col xs={12} sm={6} md={3} lg={3} xl={3}>
         <ProfileSidebar/>
        </Col>
       
        <Col>
        <div className='d-flex justify-content-between'>
        <h4 className='mt-4'>My Work</h4>
        <Link to='/workpop'>
        <Button size='sm'  variant="outline-success "  
        className=''> + Add Work</Button>
        </Link>
       
        
        </div>
        
                        <Row xs={2} sm={2} md={2} lg={3} xl={4} className='row-cols-auto'>
                     
                            {workcard && workcard.map((data) => (
                                <Col key={data.mywork_id}>
                                    <Card className='border-0'>
                                        <div onContextMenu={(e) => e.preventDefault()}>
                                            {/* <Card.Img variant="top" src={data.image} className='img-thumbnail border-0 img-border-radius' /> */}
                                            {/* <Card.Img variant="top" src='images/image1.png' style={{objectFit:"cover"}}
                                             className='img-thumbnail border-0 img-border-radius img-w-h' /> */}
                                             <Card.Img variant="top" src={data.image} style={{objectFit:"fill"}}
                                             className='img-thumbnail border-0 img-border-radius img-w-h' />
                                        </div>
                                        <Card.Body>
                                            <Card.Text className=''>
                                                <h4 className='font-weight-bold'>{data.movie_name}</h4>
                                                <h6 className='text-muted'>Role: {data.role}</h6>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>
                            ))}
                        </Row>
                    </Col>
      </Row>
      </Container>
      <Footer/>
    </div>
  );
}

export default MyWork;
