import React,{useState} from 'react';
import { Button, Form ,Col,Row ,Card, CardBody} from 'react-bootstrap';
import { BASE_URL } from '../../GlobalUtil';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';





const MyWorkPopUp = () => {

  const [movie_name, setMovieName] = useState('');
  const [worked_as, setWorkedAs] = useState('');
  const [role, setRole] = useState('');
  const [movie_description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate=useNavigate();
  const token = localStorage.getItem('authToken');

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${BASE_URL}/api/myworks/`;

    // const formData = {
    //   movie_name,
    //   worked_as,
    //   role,
    //   movie_description,
    //   if (selectedFile) {
    //     formData.append('image', selectedFile); // Updated to use selectedFile
    //   }
      
    // };
    const formData = new FormData();
    formData.append('movie_name', movie_name);
    formData.append('worked_as', worked_as);
    formData.append('role', role);
    formData.append('movie_description', movie_description);
    
    if (selectedFile) {
      formData.append('image', selectedFile); 
    }

    try {
      const response = await axios.post(url, formData ,{
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json'
          'Content-Type': 'multipart/form-data'
        }
      }


      );
      console.log('Response:', response.data);
      if(response.status===201){
        navigate('/mywork')
      }
      
    } catch (error) {
      console.error('Error:', error);
    
    }
  };

  return (
    <div className=' mx-auto popup-shadow  py-2 px-4 mt-5' style={{width:"500px",minHeight:"630px"}}>
      <h3 className='text-center font-weight-bold'>Add Work</h3>
      <p className='text-muted text-center'>Add your recent work</p>
      <Row>
        <Col lg={5} sm={5} md={5} xl={5} xs={5}>
         
            {/* <Card.Img variant="top" src='images/image1.png' className='img-thumbnail  border-0 img-border-radius' /> */}
            {/* <Card.Img variant="top"  src={selectedFile ? URL.createObjectURL(selectedFile) : ''}
             className='img-thumbnail  border-0 img-border-radius ' style={{height:"90%"}}  /> */}

             {selectedFile ? (
            <Card.Img
              variant="top"
              src={URL.createObjectURL(selectedFile)}
              className='img-thumbnail border-0 img-border-radius'
              style={{height:"90%"}}
            />
          ) : (
            <Card className="text-center text-muted " style={{height:"90%"}}>
            <CardBody className='text-center d-flex align-items-center justify-content-center'>
            <p className='text-center  w-100 '>No Photo</p>
            </CardBody>
              
            </Card>
          )}
            <Form.Group controlId="formFile" className=" bg-dark" 
            style={{color:"white",textAlign:"center",cursor:"pointer"}}>
            <Form.Label style={{cursor:"pointer"}}>Edit / Upload Photo</Form.Label>
            <Form.Control type='file'  onChange={handleFileChange}/>
          </Form.Group>
            
        </Col>
        <Col lg={7} sm={7} md={7} xl={7} xs={7}>
        <Form >
       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Movie Name *</Form.Label>
                      <Form.Control type="text" placeholder="" 
                        value={movie_name}
                        onChange={(e) => setMovieName(e.target.value)}
                      />
                    </Form.Group>  

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                      <Form.Label>Worked as *</Form.Label>
                      <Form.Select
                      value={worked_as}
                      onChange={(e) => setWorkedAs(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="ACTOR">Actor</option>
                      <option value="ACTRESS">Actress</option>
                      <option value="COMEDIAN">Comedian</option>
                      <option value="VILLAIN">Villian</option>
                      <option value="DIRECTOR">Director</option>
                    </Form.Select>
                    </Form.Group> 
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Role *</Form.Label>
                      <Form.Control type="text" placeholder="" 
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      />
                    </Form.Group>  
          
        </Form>
      
        </Col>
      </Row>
      <Row>
        <Col className='mt-5 pt-3'>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Movie Description</Form.Label>
        <Form.Control as="textarea" rows={3} 
          value={movie_description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder='Write about your contribution...'
        />
      </Form.Group>
      <Button size='md' className='btn-gradient border-0' onClick={handleSubmit}>Save</Button>
     
        </Col>
       
      </Row>
    </div>
  );
}

export default MyWorkPopUp;
