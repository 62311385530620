import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Heart, HeartFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../GlobalUtil';
import { getTokenRes1 } from '../CommonUtil/Util';
import ProjectModal from '../PopupMsg/CreateShortList';
import ProjectPopup from '../PopupMsg/ProjectPopup';

const ImageCard = ({ urlFilter }) => {
  const prevUrlFilter = useRef(null);
  const [talentcard, setTalentCard] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState();
  const [selectedActorId, setSelectedActorId] = useState(null);

  useEffect(() => {
    const TalentCardDetails = async () => {
      // debugger;
      try {
        let response;

        if (urlFilter) {
          const token = getTokenRes1();

          response = await axios.get(urlFilter, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
        } else {
          const token = getTokenRes1();

          response = await axios.get(`${BASE_URL}/api/talent/list`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
        }
        if (Array.isArray(response.data)) {
          setTalentCard(response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function only if urlFilter has changed
    if (urlFilter !== prevUrlFilter.current) {
      TalentCardDetails();
      prevUrlFilter.current = urlFilter;
    }
  }, [urlFilter]);

  const handleShortlistClick = (actorIdNum) => {
    setSelectedActorId(actorIdNum);
    setShowModal(true);
  };

  return (
    <div className='mt-4'>
      <div className={` ${showPopup ? 'blur-background' : ''} `}>
        <Row xs={2} sm={2} md={2} lg={3} xl={4}>
          {talentcard &&
            talentcard.map((data) => (
              <Col
                className='d-flex flex-column justify-content-between'
                key={data.id}
              >
                <Link
                  to={`/talentdetail/${data.id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Card className='border-0' style={{ cursor: 'pointer' }}>
                    <div onContextMenu={(e) => e.preventDefault()}>
                      <Card.Img
                        variant='top'
                        src={data.image.file}
                        className='img-thumbnail border-0 img-border-radius img-w-h'
                        style={{ objectFit: 'cover' }}
                        alt='not loaded'
                      />
                    </div>
                    <Card.Body>
                      <Card.Text className='text-center'>
                        <div className='h4'>{data.talent_name}</div>
                        <div className='h6'>
                          <span className='text-muted'>Age: </span>
                          {data.age} |{' '}
                          <span className='text-muted'>Height: </span>
                          {data.height} |{' '}
                        </div>
                        <h6>
                          Chennai |{' '}
                          {Array.isArray(data.languages)
                            ? data.languages.join(', ')
                            : data.languages
                                .split(',')
                                .map((lang) => lang.trim())
                                .join(', ')}
                        </h6>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
                <ShortlistButton
                  onClick={handleShortlistClick}
                  actorIdNum={data.id}
                />
                <ProjectModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  actorIdNum={selectedActorId}
                />
              </Col>
            ))}
        </Row>
      </div>

      <div className='text-center my-4'>
        <Button
          style={{ background: '#ced4da' }}
          className='text-dark w-40 border-0 '
          size='lg'
        >
          LOAD MORE
        </Button>
      </div>
      {showPopup && (
        <div className=''>
          <ProjectPopup onClose={() => setShowPopup(false)} />
        </div>
      )}
    </div>
  );
};
const ShortlistButton = ({ onClick, actorIdNum }) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleClick = (actorIdNum) => {
    setClicked(!clicked);
    onClick(actorIdNum);
  };

  return (
    <Button
      variant='outline-success w-100'
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onClick={() => handleClick(actorIdNum)}
      style={{
        backgroundColor: clicked ? 'black' : hovered ? 'black' : 'transparent',
        color: clicked ? 'white' : hovered ? 'white' : 'black',
        borderColor: hovered || clicked ? 'red' : 'black',
      }}
    >
      {clicked || hovered ? <HeartFill color='red' /> : <Heart />}
      <span className='mx-3 '>Shortlist</span>
    </Button>
  );
};

export default ImageCard;
