import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container,  Row, Toast, ToastContainer } from 'react-bootstrap';
// FloatingLabel, Form,
// import { ShorlistedData } from '../datas/shortlistedcard';
import BookNow from '../SubPages/ShortListed/BookNow';
import Navbarresp from '../Navbar/navbar';
import Footer from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../GlobalUtil';

const ShortlistesTalent = () => {
    const {project_id}=useParams();
    // console.log("project_id",project_id)
    const [ShortListedData, setShortListedData] = useState("")
    const [talentsDetails, setTalentsDetails] = useState("")
    const [success, setSuccess] = useState("")
    const [show, setShow] = useState(false)
    // console.log("tal",ShortListedData)

  
        const fetchShortlists = async () => {
          try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`${BASE_URL}/api/projects/${project_id}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            // console.log("tt",response.data.project);
            // console.log("ss",response.data.talents);

            setShortListedData(response.data.project)
            setTalentsDetails(response.data.talents)
          } catch (error) {
            console.error('Error fetching projects:', error);
          }
        };
        useEffect(() => {
          fetchShortlists();
      }, []);

      const handleDeleteShortlist = async (id,projectId) => {
        try {
          const token = localStorage.getItem('authToken');
          const deleteUrl = `${BASE_URL}/api/projects/talents/delete`;
          const requestData = {
              project:projectId,
              talent: id
            
          };
    
          const response =await axios.delete(deleteUrl, {
            data: requestData,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });

          setSuccess(response.data.msg)
          setShow(true)
          setTimeout(() => {
            setShow(false);
            setSuccess("")
          }, 3000);
    
          fetchShortlists();
        } catch (error) {
          fetchShortlists();
          console.error("Error deleting note:", error);
        }
      };


  return (
    <div>
    <Navbarresp showLoginButton={false} showShortlistButton={true}/>
           <Container>
    
     <div className='mt-2'>
     
             <Row>
                {/* Shortlisted Cards */}
                <div className='d-flex'>
                        <h4 className='font-weight-bold'>Shortlisted ({ShortListedData.shortlist_count})</h4>
                        <h4 className='ml-1 text-muted' style={{ marginLeft: '15px' }}>|</h4>
                        <h4 className='ml-1 font-weight-bold' style={{ marginLeft: '15px' }}>{ShortListedData.name}</h4>
                    </div>
                <Col lg={7} xl={8}>
                {/* <div className='d-flex'>
                        <h4 className='font-weight-bold'>Shortlisted (24)</h4>
                        <h4 className='ml-1 text-muted' style={{ marginLeft: '15px' }}>|</h4>
                        <h4 className='ml-1 font-weight-bold' style={{ marginLeft: '15px' }}>Untitled</h4>
                    </div> */}
                    <Row xs={1} sm={2} md={2} lg={3} xl={3} className='row-cols-auto'>
                        {talentsDetails && talentsDetails.length > 0 && talentsDetails.map((data, index) => (
                            <Col key={index}>
                                <Card className='border-0 '>
                                    <Card.Body className='shortlist-card-border'>
                                        <Card.Img variant="top" src={data.image.file} className='img-thumbnail border-0 img-border-radius' />
                                        <Card.Text className='text-center'>
                                            <h2>{data.talent_name}</h2>
                                            <h5 className='text-muted'>Age: {data.age}</h5>
                                              <h6> Chennai | {
                                                      Array.isArray(data.languages)
                                                      ? data.languages.join(', ')
                                                      : data.languages.split(',').map(lang => lang.trim()).join(', ')
                                                             }
                                              </h6>
                                              <h6>{
                                                Array.isArray(data.interests)
                                                ? data.interests.join(', ')
                                                : data.interests.split(',').map(int => int.trim()).join(', ')
                                                  }
                                                </h6>
                                            <Button className='w-100 bg-dark img-border-radius' onClick={() => handleDeleteShortlist(data.id,ShortListedData.project_id)}>
                                                <span className='me-2'><i className="fa-solid fa-xmark"></i></span> Remove
                                            </Button>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
                {/* Book Now Section */}
                <Col lg={5} xl={4}  >
                    <BookNow />
                </Col>
            </Row>
        </div>
      
</Container>
<Footer/>
<ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
      <Toast className="bg-success" onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Great</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>{success}</Toast.Body>
        </Toast>
        </ToastContainer>

    </div>
 
    
  );
 
}

export default ShortlistesTalent;
// import React from 'react';
// import { Button, Card, Col, Container, Row } from 'react-bootstrap';
// import { ShorlistedData } from '../datas/shortlistedcard';
// import BookNow from '../SubPages/ShortListed/BookNow';
// import Navbarresp from '../Navbar/navbar';
// import Footer from '../Footer/Footer';

// const ShortlistesTalent = () => {
//   return (
//     <div>
//       <Navbarresp showLoginButton={false} showShortlistButton={true} />
//       <Container>
//         <div className='mt-2'>
//           <Row>
//             {/* Shortlisted Cards */}
//             <Col xs={12} sm={12} md={7} lg={7} xl={8}>
//               <div className='d-flex'>
//                 <h4 className='font-weight-bold'>Shortlisted (24)</h4>
//                 <h4 className='ml-1 text-muted' style={{ marginLeft: '15px' }}>|</h4>
//                 <h4 className='ml-1 font-weight-bold' style={{ marginLeft: '15px' }}>Untitled</h4>
//               </div>
//               <Row xs={1} sm={2} md={2} lg={3} xl={4} className='row-cols-auto'>
//                 {ShorlistedData.map((data, index) => (
//                   <Col key={index}>
//                     <Card className='border-0'>
//                       <Card.Body className='shortlist-card-border'>
//                         <Card.Img variant="top" src={data.thumb} className='img-thumbnail border-0 img-border-radius' />
//                         <Card.Text className='text-center'>
//                           <h2>{data.name}</h2>
//                           <h5 className='text-muted'>{data.city}</h5>
//                           <Button className='w-100 bg-dark img-border-radius'>
//                             <span className='me-2'><i className="fa-solid fa-xmark"></i></span> {data.buttontext}
//                           </Button>
//                         </Card.Text>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                 ))}
//               </Row>
//             </Col>
//             {/* Book Now Section */}
//             <Col xs={12} sm={12} md={5} lg={5} xl={4}>
//               <div className="mt-md-0">
//                 <BookNow />
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </Container>
//       <Footer />
//     </div>
//   );
// }

// export default ShortlistesTalent;


