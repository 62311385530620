import React, {  useState,useEffect } from 'react';
import axios from 'axios';
import { Row,Col,Card, Container, Button, Stack, Badge } from 'react-bootstrap';
import { HeartFill,Heart} from 'react-bootstrap-icons';
import Carousel from 'react-bootstrap/Carousel';
// import Sidebar from '../SideBar/Sidebar';
import '../SideBar/Sidebar.css'
import Navbarresp from '../Navbar/navbar';
import Footer from '../Footer/Footer';
import { BASE_URL } from '../../GlobalUtil';
import { Outlet, useParams } from 'react-router-dom';

import ProjectModal from '../PopupMsg/CreateShortList';
// import { getTokenRes1 } from '../CommonUtil/Util';

// import ProjectPopup from '../PopupMsg/ProjectPopup';
// import CreateProject from '../PopupMsg/CreateProject';
// import imag from '/suresh/chamcast/talentpage/images/image.png'
// import  SuccessfulPopUp from '../PopupMsg/SuccessfulPopUp'


const TalentDetail = () => {
  const {tid}=useParams();
  console.log("talent id is",tid);
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [talentinfo,setTalentInfo]=useState('');
  const [showModal, setShowModal] = useState(false)



  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleClick = () => {
    setClicked(!clicked);
    setShowModal(true)
  };
  const iconStyles = {
    fontWeight: 'bold',
    color: 'black'
};

const url=`${BASE_URL}/api/talent/${tid}/getdetails`;
// const url=`${BASE_URL}/api/talent/${tid}`;
useEffect(()=>{
  
  const TalentDetailPage=async()=>{
      try {
        const token = localStorage.getItem('authToken');
        // const token =getTokenRes1;

          const response = await axios.get(url,{
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
            
          );
          setTalentInfo(response.data);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
  }
  TalentDetailPage();
 

},[url])

  return (
    <div>
    
    
      <div>
      <Navbarresp showLoginButton={false} showShortlistButton={true}/>
      {/* <ProjectPopup/> */}
      <Container>
     
      <Row>
            <Col>
            <Col xs={12}>
            <Row>
            <Col xs={ 12} sm={4} md={4} lg={3}>
            <div>
              <Card className='border-0'>
            {/* <Card.Img variant="top" src="/images/image.png" className='img-thumbnail border-0 img-border-radius' /> */}
            {talentinfo.images && talentinfo.images.length > 0 && (
              <Card.Img
                variant="top"
                src={talentinfo.images[0].file}
                className='img-thumbnail border-0 img-border-radius img-w-h'
              />
            )}
            <Card.Body>
              <Card.Text className='text-center'>
                {/* <h4>Actor1</h4> */}
                <h4>{talentinfo.talent_name}</h4>
                {/* <h6><span className='text-muted'>chennai.44yrs</span></h6> */}
                <h6><span className='text-muted'>chennai.{talentinfo.age}</span></h6>
                {/* <Button variant="outline-dark w-100"  ><Heart /><span className='mx-3'>Shortlist</span></Button>{' '} */}
                <Button
                variant="outline-success w-100"
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={handleClick}
                style={{
                  backgroundColor: clicked ? 'black' : hovered ? 'black' : 'transparent',
                  color: clicked ? 'white' : hovered ? 'white' : 'black',
                  borderColor: hovered || clicked ? 'red' : 'black',
                
                 
                }}
   
    >
              
                {/* <HeartFill color={clicked || hovered ? 'red' : 'black'}  /> */}
                {clicked || hovered ? <HeartFill color='red' /> : <Heart />} {/* Conditionally render HeartFill when clicked or hovered */}
               
                <span className='mx-3' >Shortlist</span>
            
              </Button>
               
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='border-0'>
           
            <Card.Body clas>
            <h5>Skills</h5>
           
              {/* <Card.Text className='text-center'>
          <Stack direction="horizontal" gap={2} className='d-flex flex-wrap'>
      <Badge pill  className='py-2 px-4 badge-bg' >
        Acting
      </Badge>
      <Badge pill  className='py-2 px-4 badge-bg'  >
        Comedy
      </Badge>
      <Badge pill  className='py-2 px-4 badge-bg'  >
        photography
      </Badge>
      <Badge pill  className='py-2 px-4 badge-bg'  >
        Acting
      </Badge>
      <Badge pill  className='py-2 px-4 badge-bg'  >
        Comedy
      </Badge>
      <Badge pill  className='py-2 px-4 badge-bg'  >
        photography
      </Badge>
     
    </Stack>
              </Card.Text> */}
                    {talentinfo.skills && talentinfo.skills.length > 0 && (
        <Card.Text className='text-center'>
          <Stack direction="horizontal" gap={2} className='d-flex flex-wrap'>
            {talentinfo.skills.map((skill, index) => (
              <Badge key={index} pill className='py-2 px-4 ' style={{ backgroundColor: '#e5f2cf' }}>
                {skill}
              </Badge>
            ))}
          </Stack>

        </Card.Text>
)}

            </Card.Body>
          </Card>
          <Card className='border-0'>
           
           <Card.Body>
           <h5>Languages</h5>
             {/* <Card.Text className='text-center'>
             <Stack direction="horizontal" gap={2} className='d-flex flex-wrap'>
     <Badge pill  className='py-2 px-4 badge-bg' >
       Tamil
     </Badge>
     <Badge pill  className='py-2 px-4 badge-bg' >
       Telegu
     </Badge>
     <Badge pill  className='py-2 px-4 badge-bg' >
       Malayalaam
     </Badge>
     <Badge pill  className='py-2 px-4 badge-bg' >
       Hindi
     </Badge>
  
    
   </Stack>
             </Card.Text> */}
             {talentinfo.languages && talentinfo.languages.length > 0 && (
            <Card.Text className='text-center'>
              <Stack direction="horizontal" gap={2} className='d-flex flex-wrap'>
                {talentinfo.languages.map((language, index) => (
                  <Badge key={index} pill className='py-2 px-4 badge-bg'  style={{ backgroundColor: '#e5f2cf' }}>
                    {language}
                  </Badge>
                ))}
              </Stack>
            </Card.Text>
          )}

           </Card.Body>
         </Card>
              </div>
            </Col>
             
            
         {/* carousel section starts */}
         <Col xs={ 12} sm={8} md={8} lg={9}>
         <Card className='border-0 d-block d-md-none' >
            <Card.Body>
            <Carousel interval={null}>
      <Carousel.Item>
        
       <img src="/images/image.png"  className='img-thumbnail border-0 img-border-radius  ' alt="" />
      </Carousel.Item>
      <Carousel.Item>
      
        <img src="/images/image.png"  className='img-thumbnail border-0 img-border-radius ' alt="" />
      
      </Carousel.Item>
      <Carousel.Item>
      
        <img src="/images/image.png"  className='img-thumbnail border-0 img-border-radius' alt="" />
        
      </Carousel.Item>
      <Carousel.Item>
      
      <img src="/images/image.png"  className='img-thumbnail border-0 img-border-radius' alt="" />
      
    </Carousel.Item>
    </Carousel>
    <div className='d-flex gap-4 flex-wrap mt-2  '>
    <div className=''>
         <h6>{talentinfo.age}</h6>
        <h6 className='text-muted'>Age</h6>
    </div>
    <div>
         <h6>{talentinfo.height}</h6>
        <h6 className='text-muted'>Height</h6>
    </div>
    <div>
         <h6>{talentinfo.gender}</h6>
        <h6 className='text-muted'>Sex</h6>
    </div>
    <div>
         <h6>Indian</h6>
         {/* <h6>{talentinfo.ethnicity}</h6> */}
        <h6 className='text-muted'>Ethinicity</h6>
    </div>
    <div>
         <h6>Chennai</h6>
        <h6 className='text-muted'>Location</h6>
    </div>
    {/* <div className=''>
         <h6>Movies,Web Series,Ad Films</h6>
        <h6 className='text-muted'>Interested in</h6>
    </div> */}
    {talentinfo.interests && talentinfo.interests.length > 0 && (
  <div className=''>
    <h6>{talentinfo.interests.join(',')}</h6>
    <h6 className='text-muted'>Interested in</h6>
  </div>
)}

      
    </div>
    <hr/>
    {/* descripton div*/}
    
    <Row>
    <Col lg={5} className='' >
    <div className='' >
            <p className='text-muted '>
            {talentinfo.bio}
            </p>
        </div>
    </Col>
   
        <Col lg={7}>
        <div className='d-flex flex-wrap gap-3 w-100'>
        <div >
        <h6>{talentinfo.year_of_experience}yrs</h6>
        <h6 className='text-muted'>Experience</h6>
        </div>
        <div>
        <h6>08</h6>
        <h6 className='text-muted'>Awards</h6>
        </div>
        <div >
        <h6>32</h6>
        <h6 className='text-muted'>Movies</h6>
        </div>
        <div>
        <h6>02</h6>
        <h6 className='text-muted'>Web Series</h6>
        </div>
        </div>
        </Col>
    </Row>
    
            </Card.Body>
         </Card>
        
        <Card className='d-none d-md-flex gap-4 flex-wrap mt-2 flex-row border-0'>
       
        <Row className="justify-content-center">
                <Col>
                    <Carousel interval={null} className="d-none d-lg-block">
                        {/* Show four carousel items on LG and XL screens */}
                        <Carousel.Item >
                     
                            <div className="d-flex ">
                            <video  className='img-thumbnail border-0 w-75  img-border-radius' controls  width="80%" 
                            style={{height:"240px"}} >
                                    <source src="/images/video.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius '  alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
                            </div>
        
                           
                            
                        </Carousel.Item>
                        <Carousel.Item >
                            <div className="d-flex ">
                            {/* <video className='img-thumbnail border-0 w-25 h-100 img-border-radius' controls  width="100%" height="100%">
                                    <source src="/images/video.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
                            </div>
                        </Carousel.Item>
                        </Carousel>
                        {/* Show two carousel items on MD, SM, and XS screens */}
                        <Carousel className="d-block d-lg-none" interval={null}>
                        <Carousel.Item >
                            <div className="d-flex ">
                                <img src="/images/image.png" className='img-thumbnail border-0 w-50 h-50 img-border-radius' alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-50 h-50 img-border-radius' alt="" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item >
                            <div className="d-flex justify-content-lg-around">
                                <img src="/images/image.png" className='img-thumbnail border-0 w-50 h-50 img-border-radius' alt="" />
                                <img src="/images/image.png" className='img-thumbnail border-0 w-50 h-50 img-border-radius' alt="" />
                            </div>
                        </Carousel.Item>
                        </Carousel>
                      
                        {/* Add more Carousel.Item components if needed */}
                  
                </Col>
            </Row>
        <Col className='w-100' md={12}>
        <div className='d-flex gap-4 flex-wrap mt-2  '>
    <div className=''>
         <h6>{talentinfo.age}</h6>
        <h6 className='text-muted'>Age</h6>
    </div>
    <div>
         <h6>{talentinfo.height}</h6>
        <h6 className='text-muted'>Height</h6>
    </div>
    <div>
         <h6>{talentinfo.gender}</h6>
        <h6 className='text-muted'>Sex</h6>
    </div>
    <div>
         <h6>Indian</h6>
         {/* <h6>{talentinfo.ethnicity}</h6> */}
        <h6 className='text-muted'>Ethinicity</h6>
    </div>
    <div>
         <h6>Chennai</h6>
        <h6 className='text-muted'>Location</h6>
    </div>
    {/* <div>
         <h6>Movies,Web Series,Ad Films</h6>
        <h6 className='text-muted'>Interested in</h6>
    </div> */}
    {talentinfo.interests && talentinfo.interests.length > 0 && (
  <div className=''>
    <h6>{talentinfo.interests.join(',')}</h6>
    <h6 className='text-muted'>Interested in</h6>
  </div>
)}

      
    </div>
    <hr/>
    {/* descripton div*/}
    <div className=''>
    <Row>
    <Col xl={8} lg={8} md={6}>
    <div>
            <p className='text-muted'>
            {talentinfo.bio}
            </p>
        </div>
        </Col>
        {/* <Col lg={5} className='bg-primary'> 
        <div className='  w-100'>
        <div className='d-flex justify-content-between align-items-center w-100'>
        <div >
        <h6>10yrs</h6>
        <h6 className='text-muted'>Experience</h6>
        </div>
        <div>
        <h6>08</h6>
        <h6 className='text-muted'>Awards</h6>
        </div>
        </div>
       <div className='d-flex justify-content-between align-items-center w-100 mt-4' >
       <div className=''>
        <h6>32</h6>
        <h6 className='text-muted'>Movies</h6>
        </div>
        <div className=''>
        <h6>02</h6>
        <h6 className='text-muted'>Web Series</h6>
        </div>
       </div>
        
        </div>
        </Col> */}
        <Col xl={4} lg={4}  md={6} className=''> 
    <div className='d-flex flex-wrap w-100'>
        <div className='d-flex justify-content-between align-items-center   text-center   w-100'>
            <div className='text-center'>
                <h6>{talentinfo.year_of_experience}</h6>
                <h6 className='text-muted'>Experience</h6>
            </div>
            <div className='d-flex flex-column text-center align-items-center  w-50'>
                <h6  className=''>08</h6>
                <h6 className='text-muted'>Awards</h6>
            </div>
        </div>
        <div className='d-flex justify-content-between  text-center align-items-center   w-100  mt-4'>
            <div className='text-center'>
                <h6>32</h6>
                <h6 className='text-muted'>Movies</h6>
            </div>
            <div  className='d-flex flex-column align-items-center w-50'>
                <h6 className=''>02</h6>
                <h6 className='text-muted'>Web Series</h6>
            </div>
        </div>
    </div>
</Col>
    </Row>
    
       
        {/* miovies */}
        <div className='mt-2 border-0 d-md-block d-sm-none'>
         <Card className='border-0'>
            <Card.Body>
            <h2>Best Movies</h2>
            <Carousel interval={null} className="custom-carousel" style={iconStyles}>
      <Carousel.Item>
      <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50  img-border-radius'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
      </Carousel.Item>
      <Carousel.Item>
      <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
      
      </Carousel.Item>
      <Carousel.Item>
      <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50 img-border-radius' alt="" />
        
      </Carousel.Item>
      
    </Carousel>
            </Card.Body>
         </Card>
         </div>
        {/* end movies */}




    </div>
        </Col>
            </Card>
         </Col>
            </Row>
            
            </Col>
   
        
        
         
         {/* carousel section ends */}
         {/* best movies starts */}
         <div className='mt-2 border-0  d-md-none'>
         <Card className='border-0'>
            <Card.Body>
            <h2>Best Movies</h2>
            <Carousel interval={null}>
      <Carousel.Item>
      <img src="/images/image.png"  className='img-thumbnail border-0 w-50 h-50'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-50 h-50' alt="" />
       {/* <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50'  alt="" /> */}
       {/* <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50' alt="" /> */}
      </Carousel.Item>
      <Carousel.Item>
      <img src="/images/image.png"  className='img-thumbnail border-0 w-50 h-50'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-50 h-50' alt="" />
       {/* <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50'  alt="" /> */}
       {/* <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50' alt="" /> */}
      
      </Carousel.Item>
      <Carousel.Item>
      <img src="/images/image.png"  className='img-thumbnail border-0 w-50 h-50'  alt="" />
       <img src="/images/image.png"  className='img-thumbnail border-0 w-50 h-50' alt="" />
       {/* <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50'  alt="" /> */}
       {/* <img src="/images/image.png"  className='img-thumbnail border-0 w-25 h-50' alt="" /> */}
        
      </Carousel.Item>
      
    </Carousel>
            </Card.Body>
         </Card>
         </div>
         {/* <Card className='d-none d-md-flex gap-4 flex-wrap mt-2 flex-row border-0'>
                <div className='col-md-3'>
                    <Card.Img variant="top" src="/images/image.png" className='img-thumbnail border-0' alt="" />
                </div>
                <div className='col-md-3'>
                    <Card.Img variant="top" src="/images/image.png" className='img-thumbnail border-0' alt="" />
                </div>
                <div className='col-md-3'>
                    <Card.Img variant="top" src="/images/image.png" className='img-thumbnail border-0' alt="" />
                </div>
                <div className='col-md-3'>
                    <Card.Img variant="top" src="/images/image.png" className='img-thumbnail border-0' alt="" />
                </div>
            </Card> */}
           
         {/* best movies ends */}
            </Col>
        </Row>
       
      </Container>
      <Footer/>
      {/* <SuccessfulPopUp/> */}
      {/* <ProjectPopup/> */}
      {/* <CreateProject/> */}
      </div>
      <ProjectModal
        show={showModal}
        onHide={() => setShowModal(false)}
        actorIdNum={tid}
      />
      <Outlet/>
    </div>
  );
}

export default TalentDetail;
