import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Stack
} from 'react-bootstrap';
// import { HeartFill,Heart} from 'react-bootstrap-icons';
import Carousel from 'react-bootstrap/Carousel';
// import Sidebar from '../SideBar/Sidebar';
import { Link, useParams } from 'react-router-dom';
import { BASE_URL } from '../../GlobalUtil';
import { useCookies } from '../../utils/useCookies';
import Footer from '../Footer/Footer';
import Navbarresp from '../Navbar/navbar';
import '../SideBar/Sidebar.css';

const MyProfile = () => {
  const { tid } = useParams();
  console.log('talent id is', tid);
  // const [hovered, setHovered] = useState(false);
  // const [clicked, setClicked] = useState(false);
  const [talentinfo, setTalentInfo] = useState('');
  const { getCookie } = useCookies();

  // const handleHover = () => {
  //   setHovered(!hovered);
  // };

  // const handleClick = () => {
  //   setClicked(!clicked);
  // };
  const iconStyles = {
    fontWeight: 'bold',
    color: 'black'
  };

  // const url=`${BASE_URL}/api/talent/${tid}/getdetails`;
  // const url=`${BASE_URL}/api/profile/`
  // const url=`${BASE_URL}/editProfile`;

  useEffect(() => {
    localStorage.setItem('authToken', getCookie('auth_token'));
    const TalentProfilePage = async () => {
      try {
        const token = localStorage.getItem('authToken');
        // const userType=localStorage.getItem('userType');
        // const user_id=localStorage.getItem('user_id');
        // const url = `${BASE_URL}/api/talent/profile/${user_id}`;
        const url = `${BASE_URL}/api/talent/profile`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setTalentInfo(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    TalentProfilePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <Navbarresp showLoginButton={false} showShortlistButton={true} />
        {/* <ProjectPopup/> */}
        <Container>
          <Row>
            <Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12} sm={4} md={4} lg={3}>
                    <div>
                      <Card className='border-0'>
                        {/* <Card.Img variant="top" src="/images/image.png" className='img-thumbnail border-0 img-border-radius' /> */}
                        {talentinfo.talent_image &&
                          talentinfo.talent_image.length > 0 && (
                            <Card.Img
                              variant='top'
                              src={talentinfo.talent_image[0].file}
                              className='img-thumbnail border-0 img-border-radius img-w-h'
                            />
                          )}
                        <Card.Body>
                          <Card.Text className='text-center'>
                            {/* <h4>Actor1</h4> */}
                            <h4>{talentinfo.talent_name}</h4>
                            {/* <h6><span className='text-muted'>chennai.44yrs</span></h6> */}
                            <h6>
                              <span className='text-muted'>
                                chennai.{talentinfo.age} yrs
                              </span>
                            </h6>
                            {/* <Button variant="outline-dark w-100"  ><Heart /><span className='mx-3'>Shortlist</span></Button>{' '} */}
                            <Link to='/profileedit'>
                              <Button
                                variant='outline-dark w-100'
                                // onMouseEnter={handleHover}
                                // onMouseLeave={handleHover}
                                // onClick={handleClick}
                                // style={{
                                //   backgroundColor: clicked ? 'black' : hovered ? 'black' : 'transparent',
                                //   color: clicked ? 'white' : hovered ? 'white' : 'black',
                                //   borderColor: hovered || clicked ? 'red' : 'black',

                                // }}
                              >
                                {/* <HeartFill color={clicked || hovered ? 'red' : 'black'}  /> */}
                                {/* {clicked || hovered ? <HeartFill color='red' /> : <Heart />} Conditionally render HeartFill when clicked or hovered */}

                                <span className='mx-3'>Edit Profile</span>
                              </Button>
                            </Link>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className='border-0'>
                        <Card.Body>
                          <h5>Skills</h5>

                          {talentinfo.skills &&
                            talentinfo.skills.length > 0 && (
                              <Card.Text className='text-center'>
                                <Stack
                                  direction='horizontal'
                                  gap={2}
                                  className='d-flex flex-wrap'
                                >
                                  {talentinfo.skills.map((skill, index) => (
                                    <Badge
                                      key={index}
                                      pill
                                      className='py-2 px-4 badge-bg bg-success-subtle'
                                    >
                                      {skill}
                                    </Badge>
                                  ))}
                                </Stack>
                              </Card.Text>
                            )}
                        </Card.Body>
                      </Card>
                      <Card className='border-0'>
                        <Card.Body>
                          <h5>Languages</h5>

                          {talentinfo.languages &&
                            talentinfo.languages.length > 0 && (
                              <Card.Text className='text-center'>
                                <Stack
                                  direction='horizontal'
                                  gap={2}
                                  className='d-flex flex-wrap'
                                >
                                  {talentinfo.languages.map(
                                    (language, index) => (
                                      <Badge
                                        key={index}
                                        pill
                                        className='py-2 px-4 badge-bg bg-success-subtle'
                                      >
                                        {language}
                                      </Badge>
                                    )
                                  )}
                                </Stack>
                              </Card.Text>
                            )}
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  {/* carousel section starts */}
                  <Col xs={12} sm={8} md={8} lg={9}>
                    <Card className='border-0 d-block d-md-none'>
                      <Card.Body>
                        <Carousel interval={null}>
                          <Carousel.Item>
                            <img
                              src='/images/image.png'
                              className='img-thumbnail border-0 img-border-radius  '
                              alt=''
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                              src='/images/image.png'
                              className='img-thumbnail border-0 img-border-radius '
                              alt=''
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                              src='/images/image.png'
                              className='img-thumbnail border-0 img-border-radius'
                              alt=''
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                              src='/images/image.png'
                              className='img-thumbnail border-0 img-border-radius'
                              alt=''
                            />
                          </Carousel.Item>
                        </Carousel>
                        <div className='d-flex gap-4 flex-wrap mt-2  '>
                          <div className=''>
                            <h6>{talentinfo.age}</h6>
                            <h6 className='text-muted'>Age</h6>
                          </div>
                          <div>
                            <h6>{talentinfo.height}</h6>
                            <h6 className='text-muted'>Height</h6>
                          </div>
                          <div>
                            <h6>{talentinfo.gender}</h6>
                            <h6 className='text-muted'>Sex</h6>
                          </div>
                          <div>
                            <h6>Indian</h6>
                            {/* <h6>{talentinfo.ethnicity}</h6> */}
                            <h6 className='text-muted'>Ethinicity</h6>
                          </div>
                          <div>
                            <h6>Chennai</h6>
                            <h6 className='text-muted'>Location</h6>
                          </div>

                          {talentinfo.interests &&
                            talentinfo.interests.length > 0 && (
                              <div className=''>
                                <h6>{talentinfo.interests.join(',')}</h6>
                                <h6 className='text-muted'>Interested in</h6>
                              </div>
                            )}
                        </div>
                        <hr />
                        {/* descripton div*/}

                        <Row>
                          <Col lg={5} className=''>
                            <div className=''>
                              {/* <p className='text-muted '>
            Saravanan Sivakumar (born 23 July 1975), known by his stage name Suriya, is an Indian actor and film producer. He primarily works in Tamil cinema where he is one of the highest paid actors.[3][4][5][6] He has received numerous awards including two National Film Awards,[7] six Filmfare Awards South, 
            five Tamil Nadu State Film Awards and two South Indian International Movie Awards.
            </p> */}
                              <p className='text-muted'>{talentinfo.bio}</p>
                            </div>
                          </Col>

                          <Col lg={7}>
                            <div className='d-flex flex-wrap gap-3 w-100'>
                              <div>
                                <h6>{talentinfo.year_of_experience}yrs</h6>
                                <h6 className='text-muted'>Experience</h6>
                              </div>
                              <div>
                                <h6>08</h6>
                                <h6 className='text-muted'>Awards</h6>
                              </div>
                              <div>
                                <h6>32</h6>
                                <h6 className='text-muted'>Movies</h6>
                              </div>
                              <div>
                                <h6>02</h6>
                                <h6 className='text-muted'>Web Series</h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className='d-none d-md-flex gap-4 flex-wrap mt-2 flex-row border-0'>
                      <Row className='justify-content-center'>
                        <Col>
                          <Carousel
                            interval={null}
                            className='d-none d-lg-block'
                          >
                            {/* Show four carousel items on LG and XL screens */}
                            <Carousel.Item>
                              <div className='d-flex '>
                                {/* <video  className='img-thumbnail border-0 w-75  img-border-radius' controls  width="80%" 
                            style={{height:"240px"}} > */}
                                {/* <source src="/images/video.mp4" type="video/mp4" /> */}
                                {/* <source src={talentinfo.talent_video} type="video/mp4" /> */}
                                {talentinfo.talent_video &&
                                  talentinfo.talent_video.length > 0 && (
                                    <video
                                      className='img-thumbnail border-0 w-75  img-border-radius'
                                      controls
                                      width='80%'
                                      style={{ height: '240px' }}
                                    >
                                      <source
                                        src={talentinfo.talent_video[0].file}
                                        type='video/mp4'
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}

                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius '
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                              </div>
                            </Carousel.Item>
                            <Carousel.Item>
                              <div className='d-flex '>
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                  alt=''
                                />
                              </div>
                            </Carousel.Item>
                          </Carousel>
                          {/* Show two carousel items on MD, SM, and XS screens */}
                          <Carousel
                            className='d-block d-lg-none'
                            interval={null}
                          >
                            <Carousel.Item>
                              <div className='d-flex '>
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-50 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-50 h-50 img-border-radius'
                                  alt=''
                                />
                              </div>
                            </Carousel.Item>
                            <Carousel.Item>
                              <div className='d-flex justify-content-lg-around'>
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-50 h-50 img-border-radius'
                                  alt=''
                                />
                                <img
                                  src='/images/image.png'
                                  className='img-thumbnail border-0 w-50 h-50 img-border-radius'
                                  alt=''
                                />
                              </div>
                            </Carousel.Item>
                          </Carousel>

                          {/* Add more Carousel.Item components if needed */}
                        </Col>
                      </Row>
                      <Col className='w-100' md={12}>
                        <div className='d-flex gap-4 flex-wrap mt-2  '>
                          <div className=''>
                            <h6>{talentinfo.age}</h6>
                            <h6 className='text-muted'>Age</h6>
                          </div>
                          <div>
                            <h6>{talentinfo.height}</h6>
                            <h6 className='text-muted'>Height</h6>
                          </div>
                          <div>
                            <h6>{talentinfo.gender}</h6>
                            <h6 className='text-muted'>Sex</h6>
                          </div>
                          <div>
                            <h6>Indian</h6>

                            <h6 className='text-muted'>Ethinicity</h6>
                          </div>
                          <div>
                            <h6>Chennai</h6>
                            <h6 className='text-muted'>Location</h6>
                          </div>

                          {talentinfo.interests &&
                            talentinfo.interests.length > 0 && (
                              <div className=''>
                                <h6>{talentinfo.interests.join(',')}</h6>
                                <h6 className='text-muted'>Interested in</h6>
                              </div>
                            )}
                        </div>
                        <hr />
                        {/* descripton div*/}
                        <div className=''>
                          <Row>
                            <Col xl={8} lg={8} md={6}>
                              <div>
                                {/* <p className='text-muted'>
            Saravanan Sivakumar (born 23 July 1975), known by his stage name Suriya, is an Indian actor and film producer. He primarily works in Tamil cinema where he is one of the highest paid actors.[3][4][5][6] He has received numerous awards including two National Film Awards,[7] six Filmfare Awards South, 
            five Tamil Nadu State Film Awards and two South Indian International Movie Awards.
            </p> */}
                                <p className='text-muted'>{talentinfo.bio}</p>
                              </div>
                            </Col>

                            <Col xl={4} lg={4} md={6} className=''>
                              <div className='d-flex flex-wrap w-100'>
                                <div className='d-flex justify-content-between align-items-center   text-center   w-100'>
                                  <div className='text-center'>
                                    <h6>{talentinfo.year_of_experience}</h6>
                                    <h6 className='text-muted'>Experience</h6>
                                  </div>
                                  <div className='d-flex flex-column text-center align-items-center  w-50'>
                                    <h6 className=''>08</h6>
                                    <h6 className='text-muted'>Awards</h6>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-between  text-center align-items-center   w-100  mt-4'>
                                  <div className='text-center'>
                                    <h6>32</h6>
                                    <h6 className='text-muted'>Movies</h6>
                                  </div>
                                  <div className='d-flex flex-column align-items-center w-50'>
                                    <h6 className=''>02</h6>
                                    <h6 className='text-muted'>Web Series</h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {/* miovies */}
                          <div className='mt-2 border-0 d-md-block d-sm-none'>
                            <Card className='border-0'>
                              <Card.Body>
                                <h2>Best Movies</h2>
                                <Carousel
                                  interval={null}
                                  className='custom-carousel'
                                  style={iconStyles}
                                >
                                  <Carousel.Item>
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50  img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                    <img
                                      src='/images/image.png'
                                      className='img-thumbnail border-0 w-25 h-50 img-border-radius'
                                      alt=''
                                    />
                                  </Carousel.Item>
                                </Carousel>
                              </Card.Body>
                            </Card>
                          </div>
                          {/* end movies */}
                        </div>
                      </Col>
                    </Card>
                  </Col>
                </Row>
              </Col>

              {/* carousel section ends */}
              {/* best movies starts */}
              <div className='mt-2 border-0  d-md-none'>
                <Card className='border-0'>
                  <Card.Body>
                    <h2>Best Movies</h2>
                    <Carousel interval={null}>
                      <Carousel.Item>
                        <img
                          src='/images/image.png'
                          className='img-thumbnail border-0 w-50 h-50'
                          alt=''
                        />
                        <img
                          src='/images/image.png'
                          className='img-thumbnail border-0 w-50 h-50'
                          alt=''
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          src='/images/image.png'
                          className='img-thumbnail border-0 w-50 h-50'
                          alt=''
                        />
                        <img
                          src='/images/image.png'
                          className='img-thumbnail border-0 w-50 h-50'
                          alt=''
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          src='/images/image.png'
                          className='img-thumbnail border-0 w-50 h-50'
                          alt=''
                        />
                        <img
                          src='/images/image.png'
                          className='img-thumbnail border-0 w-50 h-50'
                          alt=''
                        />
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                </Card>
              </div>

              {/* best movies ends */}
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default MyProfile;
