import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Navbarresp from '../Navbar/navbar';
import axios from 'axios';
import Footer from '../Footer/Footer';
// import { Link } from 'react-router-dom';
import { BASE_URL } from '../../GlobalUtil';
import { Outlet, useNavigate } from 'react-router-dom';

const ProjectPage = () => {
   const navigate = useNavigate();
const url = `${BASE_URL}/api/projects/`
const [projectList, setProjectList] = useState([])
// console.log(projectList)
useEffect(() => {
   fetchData();
 }, [url]);

const fetchData = async () => {
   const token = localStorage.getItem('authToken');

   try {
     const response = await axios.get(url, {
       headers: {
         'Authorization': `Bearer ${token}`,
         'Content-Type': 'application/json'
       }
     });
   //   console.log("project",response.data);
     setProjectList(response.data)
   } catch (error) {
      console.error(error)
   }
 };
 

  return (
    <div>
 <Navbarresp showLoginButton={false} showShortlistButton={true}/>
 
    <Container>
    <div  className=' mt-2 img-border-radius border px-4 py-4 mt-4'  >
     <h2 className='p-2'>My projects</h2>
     <div className='d-flex gap-5 img-border-radius  w-100 flex-wrap'>
   
        {/* <Link to='/create/project' style={{textDecoration:"none",color:'black'}}> */}
     <div className='border py-4 pointer img-border-radius text-center
     d-flex align-items-center justify-content-center ' style={{width:"500px"}}
     onClick={() => { window.location.href = "/create/project"; }}>
        <h1 className='text-center mx-auto w-100 ' style={{fontSize:"70px"}}>+</h1>
    
     </div>
        {/* </Link> */}
     {projectList && projectList.length > 0  && projectList.map((data, index) => (
     <div key={index} className='popup-shadow text-center  py-5 pointer px-3 img-border-radius
      gap-5 d-flex align-items-center justify-content-between project-hover' style={{width:"500px"}}
      // onClick={() => { window.location.href = `/shortlisted/${data.project_id}`; }} 
      onClick={() => { navigate(`/shortlisted/${data.project_id}`); }}
      >
     <div className='popup-shadow w-50 text-center py-4'>
      <h1 className='text-center w-100 h-100 mx-auto'>P</h1>
     </div>
     <div className='text-start'>
     <h1>{data.name}</h1>
      <p className='text-muted'>{data.description}</p>
      <h6 className='text-muted'>{data.shortlist_count} shortlist | 01-04-2024</h6>
     </div>
     
     </div>
     
))}
     {/* <div className='popup-shadow text-center project-hover w py-5 pointer px-3 img-border-radius
      gap-5 d-flex align-items-center justify-content-between' style={{width:"500px"}}>
     <div className='popup-shadow w-50 text-center py-4'>
      <h1 className='text-center w-100 h-100 mx-auto'>P</h1>
     </div>
     <div className='text-start'>
     <h1>Pizza 4</h1>
      <p className='text-muted'>The story is about a pizza delivery man named Michael who 
       lands in a mysterious predicament, and how it affects his life.</p>
      <h6 className='text-muted'>15 shortlist | 01-04-2024</h6>
     </div>
     
     </div>
     <div className='popup-shadow text-center project-hover w py-5 pointer px-3 img-border-radius
      gap-5 d-flex align-items-center justify-content-between' style={{width:"500px"}}>
     <div className='popup-shadow w-50 text-center py-4'>
      <h1 className='text-center w-100 h-100 mx-auto'>P</h1>
     </div>
     <div className='text-start'>
     <h1>Pizza 5</h1>
      <p className='text-muted'>The story is about a pizza delivery man named Michael who 
       lands in a mysterious predicament, and how it affects his life.</p>
      <h6 className='text-muted' style={{textDecoration:"none"}}>15 shortlist | 01-04-2024</h6>
     </div>
     
     </div> */}
     
     
     </div>
     
     {/* <div className='d-flex gap-5 mt-4'>
      <Button variant="outline-primary">Select</Button>
      <Button  variant="outline-danger">Cancel</Button>
     </div> */}
 </div>
 <Outlet />
    </Container>
    <Footer/>
    </div>
    
  );
  
}

export default ProjectPage;
