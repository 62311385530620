import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const BookNow = () => {
  return (
    <div className=' '>
      <div className=' mt-sm-4 mt-xs-4'>
        <Button
          size='md'
          className='w-100'
          style={{ color: '#fff', background: '#333' }}
        >
          <span className='me-4'>
            <i className='fa fa-print' aria-hidden='true'></i>
          </span>
          Print
        </Button>
        <Button
          size='md'
          className='w-100 mt-2'
          style={{ color: '#fff', background: '#7ebc11' }}
        >
          <span className='me-4'>
            <i className='fas fa-save'></i>
          </span>
          Save
        </Button>
        <Card className='mt-4 border-0'>
          <Card.Body className='card-boxshadow'>
            <div className=''>
              <h3>PROJECT DETAILS</h3>
              <p className='text-muted'>
                please enter the project details to book talents
              </p>
              <div>
                <Form.Control
                  className='mt-4'
                  size='lg'
                  type='text'
                  placeholder='Enter your name'
                />
                <Form.Control
                  className='mt-4'
                  size='lg'
                  type='text'
                  placeholder='Enter your email'
                />
                <Form.Control
                  className='mt-4'
                  size='lg'
                  type='text'
                  placeholder='Enter your mobile number'
                />
                {/* <FloatingLabel className='mt-4' controlId="floatingTextarea2" label="Write something......">
                                <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                />
                            </FloatingLabel> */}
                <Form.Control
                  className='mt-4'
                  as='textarea'
                  size='lg'
                  type='text'
                  placeholder='Write something......'
                  style={{ height: '100px' }}
                />
                {/* <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                /> */}
                <Button size='md' className='btn-gradient mt-4 w-100'>
                  Book Now
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default BookNow;
