import React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContextProvider } from '../../context/AuthContext';
import Footer from '../Footer/Footer';
import MainContainer from '../MainContainer/MainContainer';
import Navbarresp from '../Navbar/navbar';

const Talent = () => {
  return (
    <AuthContextProvider>
      <Navbarresp showLoginButton={false} showShortlistButton={true} />
      <MainContainer />
      <Footer />
      <Outlet />
    </AuthContextProvider>
  );
};

export default Talent;
