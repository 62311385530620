

export function getTokenRes1() {
 
    const isAuthToken= localStorage.getItem('authToken') !==null;
    
     if(isAuthToken){
      return localStorage.getItem('authToken');
     }

     const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const authCookieValue = getCookie('auth_token');

    if (authCookieValue) {
      localStorage.setItem('authToken', authCookieValue);

     return  authCookieValue;
    }
    
   
}
