import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const DualSlider = ({  minValue, maxValue, values, onChange  }) => {
  // const [values, setValues] = useState([minValue, maxValue]);
  const [sliderValues, setSliderValues] = useState(values);

  // useEffect(() => {
  //   onChange(values);
  // }, [values, onChange]);

  // const onSliderChange = (newValues) => {
  //   setValues(newValues);
  // };
  useEffect(() => {
    onChange(sliderValues);
  }, [sliderValues, onChange]);

  const onSliderChange = (newValues) => {
    setSliderValues(newValues);
  };


  const sliderStyle = {
    trackStyle: { backgroundColor: '#7ebc11', height: 3 },
    handleStyle: [{ backgroundColor: '#7ebc11', borderColor: '#7ebc11' }, { backgroundColor: '#7ebc11', borderColor: '#7ebc11' }],
    railStyle: { backgroundColor: '#e5e5e5', height: 3 }
  };

  return (
    <div>
      {/* <Slider
        range
        min={minValue}
        max={maxValue}
        value={values}
        onChange={onSliderChange}
        {...sliderStyle}
      /> */}
      <Slider
        range
        min={minValue}
        max={maxValue}
        value={sliderValues}
        onChange={onSliderChange}
        {...sliderStyle}
      />
      {/* <div>Min Value: {values[0]}</div>
      <div>Max Value: {values[1]}</div> */}
    </div>
  );
};

export default DualSlider;
