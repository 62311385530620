import React from 'react';
import { Link } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';

const ProfileSidebar = () => {
  return (
    <div>
        {/* <Row>
            <Col sm={4} lg={3} md={3} xl={2}>
               
            </Col>
        </Row> */}
       
        <div className='mt-4'>
        <Link to='/profileedit' style={{textDecoration:"none",color:"black"}}>
        <div className=' py-2   mt-2 text-center profile-sidebar-border'>
                Edit Profile
            </div>
        </Link>

            <Link to='/mywork' style={{textDecoration:"none",color:"black"}}>
            <div className=' py-2   mt-2 text-center profile-sidebar-border '>
                My Work
            </div>
            </Link>
          
            <div className=' py-2   mt-2 text-center profile-sidebar-border'>
            Company /Agency
            </div>
            <div className=' py-2  mt-2  text-center profile-sidebar-border'>
               Change Password
            </div>
                </div>
    </div>
  );
}

export default ProfileSidebar;
