import React, { useContext } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import {
  ArchiveFill,
  BookmarkFill,
  BoxArrowRight,
  GearFill,
  PersonFill,
} from 'react-bootstrap-icons';
import { AuthContext } from '../../context/AuthContext';

const AvatarDropdown = ({ className, userType, handleLogout }) => {
  const { user } = useContext(AuthContext);

  const CustomAvatarImg = React.forwardRef(({ onClick }, ref) => (
    <Card.Img
      variant='top'
      // src={user.profilePicture ? user.profilePicture : 'person/noAvatar.png'}
      src='/images/noAvatar.png'
      className='img-thumbnail border-0 object-fit-cover rounded-circle'
      style={{
        width: '50px',
        height: '50px',
        cursor: 'pointer',
      }}
      alt='avatar image'
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  ));

  return (
    <Dropdown className={className} align='end'>
      <Dropdown.Toggle as={CustomAvatarImg} />
      <Dropdown.Menu className='chamcast-login-menu-list'>
        <span className='arrow-up'></span>
        {userType === 'TL' && (
          <>
            <Dropdown.Item href='/myprofile'>
              <PersonFill className='mx-2' /> View Profile
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        {userType === 'HR' && (
          <>
            <Dropdown.Item href='#/my-bookings'>
              <BookmarkFill className='mx-2' /> My Bookings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href='#/saved-talents'>
              <ArchiveFill className='mx-2' /> Saved Talents
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item href='#/settings'>
          <GearFill className='mx-2' /> Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className='text-danger'>
          <BoxArrowRight className='mx-2' />
          <span onClick={handleLogout}>Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AvatarDropdown;
